import { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { CartContext } from "../context/CartContext";
import { useLocation } from 'react-router-dom';
import Loader from "../components/Loader";

const Shop = () => {
  const navigate=useNavigate();
  const{handleOutsideClick}=useOutletContext();
  const { cartItems, addItemToCart, removeItemFromCart } = useContext(CartContext);
  const [activeTab, setActiveTab] = useState('product_grid');
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state

  // Define the total number of pages
  const totalPages = 4; // You can change this based on your data

  const [products, setProducts] = useState([]);
  const location = useLocation(); // Access the location object

  // Check if location.state exists and destructure sc_id safely
  const searchParams = new URLSearchParams(location.search);
  const sc_id = searchParams.get('subcategory_id'); 
//console.log(sc_id);
  const searchTerm = searchParams.get("search");

console.log(searchTerm);


useEffect(() => {
  const fetchProducts = async () => {
    if (!sc_id && !searchTerm) return; // If both subcategory_id and searchTerm are null, don't fetch data

    try {
      console.log("Search term:", searchTerm); // Log search term for debugging
      console.log("Subcategory ID:", sc_id); // Log subcategory ID for debugging

      const response = await fetch('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/product.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Correct the request body to match API expectations
        body: JSON.stringify({
          sc_id: sc_id, // Correct field name
          search: searchTerm // Correct field name and trim the search term
        }),
      });

      const data = await response.json();

      if (data.head.code === 200) {
        setProducts(data.body.products); // Set products from API response
        console.log('Fetched products:', data.body.products); // Log fetched products
      } else {
        console.error('Error fetching products:', data.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  fetchProducts();
}, [sc_id, searchTerm]);




  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };




  const addItem = (collection, id) => {
    if (collection === "products") {
      // Find the item with the matching product_id 
      const item = products.find(item => item.product_id === id); 
  
      if (item) {
        // Add main_category_id and sub_category_id before adding to cart
        const itemWithCategoryIds = {
          ...item,  // Spread the item properties
          main_category_id: item.main_category_id || 'N/A',  // Include main_category_id
          sub_category_id: item.sub_category_id || 'N/A',    // Include sub_category_id
         
        };
        
        // Pass the updated item with category IDs to the cart
        addItemToCart(itemWithCategoryIds);
        setMessage('Item added to cart!');

        // Optionally, clear the message after a few seconds
        setTimeout(() => {
            setMessage('');
        }, 2000);

      } else {
        console.error('Product not found');
      }
    }
  };
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  if (!products.length) {
    return (
      <div className="no-products">
        <h2>No products matched your search criteria.</h2>
        <p>Please try searching with different terms or selecting another subcategory.</p>
      </div>
    );
  }





  return (
    <main class="main__content_wrapper" onClick={handleOutsideClick}>
      {/* start broadcamp section */}
      {loading ? (
        <Loader />
      ) : (
        <>
      <section className="breadcrumb__section breadcrumb__bg">
        <div className="container">
          <div className="row row-cols-1">
            <div className="col">
              <div className="breadcrumb__content text-center">
                <h1 className="breadcrumb__content--title text-white mb-25">Shop</h1>
                <ul className="breadcrumb__content--menu d-flex justify-content-center">
                  <li className="breadcrumb__content--menu__items">
                    <a className="text-white" onClick={()=>{navigate("/")}}>Home</a>
                  </li>
                  <li className="breadcrumb__content--menu__items">
                    <span className="text-white">Shop</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="shop__section section--padding">
        <div class="container">
          <div class="shop__header bg__gray--color d-flex align-items-center justify-content-between mb-30">
           {/*  <button class="widget__filter--btn d-flex align-items-center" data-offcanvas>
              <svg class="widget__filter--btn__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="28" d="M368 128h80M64 128h240M368 384h80M64 384h240M208 256h240M64 256h80" /><circle cx="336" cy="128" r="28" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="28" /><circle cx="176" cy="256" r="28" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="28" /><circle cx="336" cy="384" r="28" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="28" /></svg>
              <span class="widget__filter--btn__text">Filter</span>
            </button> */}
            <div class="product__view--mode d-flex align-items-center">
              {/* <div class="product__view--mode__list product__short--by align-items-center d-none d-lg-flex">
                <label class="product__view--label">Prev Page :</label>
                <div class="select shop__header--select">
                  <select class="product__view--select">
                    <option selected value="1">65</option>
                    <option value="2">40</option>
                    <option value="3">42</option>
                    <option value="4">57 </option>
                    <option value="5">60 </option>
                  </select>
                </div>
              </div> */}
              {/* <div class="product__view--mode__list product__short--by align-items-center d-none d-lg-flex">
                <label class="product__view--label">Sort By :</label>
                <div class="select shop__header--select">
                  <select class="product__view--select">
                    <option selected value="1">Sort by latest</option>
                    <option value="2">Sort by popularity</option>
                    <option value="3">Sort by newness</option>

                  </select>
                </div>
              </div> */}
              <div class="product__view--mode__list">
                <div class="product__grid--column__buttons d-flex justify-content-center">
                  <button class={`product__grid--column__buttons--icons ${activeTab==="product_grid"?"active":""}`} aria-label="product grid button" data-toggle="tab" data-target="#product_grid" onClick={() => setActiveTab('product_grid')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 9 9">
                      <g transform="translate(-1360 -479)">
                        <rect id="Rectangle_5725" data-name="Rectangle 5725" width="4" height="4" transform="translate(1360 479)" fill="currentColor" />
                        <rect id="Rectangle_5727" data-name="Rectangle 5727" width="4" height="4" transform="translate(1360 484)" fill="currentColor" />
                        <rect id="Rectangle_5726" data-name="Rectangle 5726" width="4" height="4" transform="translate(1365 479)" fill="currentColor" />
                        <rect id="Rectangle_5728" data-name="Rectangle 5728" width="4" height="4" transform="translate(1365 484)" fill="currentColor" />
                      </g>
                    </svg>
                  </button>
                  <button class={`product__grid--column__buttons--icons ${activeTab==="product_list"?"active":""}`} aria-label="product list button" data-toggle="tab" data-target="#product_list" onClick={() => setActiveTab('product_list')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 13 8">
                      <g id="Group_14700" data-name="Group 14700" transform="translate(-1376 -478)">
                        <g transform="translate(12 -2)">
                          <g id="Group_1326" data-name="Group 1326">n
                            <rect id="Rectangle_5729" data-name="Rectangle 5729" width="3" height="2" transform="translate(1364 483)" fill="currentColor" />
                            <rect id="Rectangle_5730" data-name="Rectangle 5730" width="9" height="2" transform="translate(1368 483)" fill="currentColor" />
                          </g>
                          <g id="Group_1328" data-name="Group 1328" transform="translate(0 -3)">
                            <rect id="Rectangle_5729-2" data-name="Rectangle 5729" width="3" height="2" transform="translate(1364 483)" fill="currentColor" />
                            <rect id="Rectangle_5730-2" data-name="Rectangle 5730" width="9" height="2" transform="translate(1368 483)" fill="currentColor" />
                          </g>
                          <g id="Group_1327" data-name="Group 1327" transform="translate(0 -1)">
                            <rect id="Rectangle_5731" data-name="Rectangle 5731" width="3" height="2" transform="translate(1364 487)" fill="currentColor" />
                            <rect id="Rectangle_5732" data-name="Rectangle 5732" width="9" height="2" transform="translate(1368 487)" fill="currentColor" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {/* <p class="product__showing--count">Showing 1–9 of 21 results</p> */}
          </div>


          <div className="row">
            <div className="col-12">
                <div className="shop__product--wrapper">
                    <div className="tab_content">
                        <div className={`tab_pane ${activeTab === 'product_grid' ? 'active show' : ''}`} id="product_grid">
    <div className="product__section--inner product__grid--inner">
        <div className="row row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30">
            {products.map((product, index) => (
                <div className="col mb-30" key={index}>
                    <div className="product__items">
                       <div className="product__items--thumbnail">
    <a 
        className="product__items--link" 
        onClick={() => navigate(`/product-details?product_id=${product.product_id}&subcategory_id=${product.subcategory_id}`)}

    >
        {/* {console.log("productid: " + product.product_id, "subcategoryid: " + product.subcategory_id)} */}
        <img className="product__items--img product__primary--img" src={product.image} alt={product.product_name} loading="lazy" />
    </a>

    <div className="product__badge">
        <span className="product__badge--items sale">Sale</span>
    </div>
</div>

                        <div className="product__items--content">
                            <h3 className="product__items--content__title h4">
                                <a onClick={() => navigate(`/product-details?id=${product.product_id}&subcategory=${product.subcategory_id}`)}>
                                    {product.product_name}
                                </a>
                            </h3>
                            <div className="product__items--price">
                                <span className="current__price">
                                    <i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {product.onlinerate}
                                </span>
                                <span className="price__divided"></span>
                                <span className="old__price">
                                    <i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {product.mrprate}
                                </span>
                            </div>
                            {message && <div className="message">{message}</div>}

                            <ul className="product__items--action d-flex">
                                {cartItems.find(cartItem => cartItem.product_id === product.product_id) === undefined ? (
                                    <li className="product__items--action__list">
                                        <a className="product__items--action__btn add__to--cart">
                                            <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534"  onClick={() => addItem("products", product.product_id)}>
                                                <g transform="translate(0 0)">
                                                    <g>
                                                        <path data-name="Path 16787" d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z" transform="translate(0 -463.248)" fill="currentColor"></path>
                                                        <path data-name="Path 16788" d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z" transform="translate(-1.191 -466.622)" fill="currentColor"></path>
                                                        <path data-name="Path 16789" d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z" transform="translate(-2.875 -466.622)" fill="currentColor"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className="add__to--cart__text" onClick={() => addItem("products", product.product_id)}>
                                                + Add to Cart
                                            </span>
                                        </a>
                                    </li>
                                ) : (
                                    <li className="product__items--action__list">
                                        <a className="product__items--action__btn add__to--cart">
                                            <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534" onClick={() => navigate("/cart")}>
                                                <g transform="translate(0 0)">
                                                    <g>
                                                        <path data-name="Path 16787" d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z" transform="translate(0 -463.248)" fill="currentColor"></path>
                                                        <path data-name="Path 16788" d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z" transform="translate(-1.191 -466.622)" fill="currentColor"></path>
                                                        <path data-name="Path 16789" d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z" transform="translate(-2.875 -466.622)" fill="currentColor"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className="add__to--cart__text" onClick={() => navigate("/cart")}>
                                                Show Cart
                                            </span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
</div>


                        <div className={`tab_pane ${activeTab === 'product_list' ? 'active show' : ''}`} id="product_list">
                            <div className="product__section--inner">
                                <div className="row row-cols-1 mb--n30">
                                    {products.map((product, index) => (
                                        <div className="col mb-30" key={index}>
                                            <div className="product__items product__list--items d-flex">
                                                <div className="product__items--thumbnail product__list--items__thumbnail">
                                                <a className="product__items--link" onClick={() => navigate(`/product-details/${product.id}`)}>
                                                       <img className="product__items--img product__primary--img" src={product.image} alt={product.product_name} loading="lazy" />
                                                          {/* <img className="product__items--img product__secondary--img" src={product.secondaryImg} alt="product-img" /> */}
                                                </a>

                                                    <div className="product__badge">
                                                        <span className="product__badge--items sale">Sale</span>
                                                    </div>
                                                </div>
                                                <div className="product__list--items__content">
                                                    <span className="product__items--content__subtitle mb-5">{product.product_name}</span>
                                                    <h3 className="product__list--items__content--title h4 mb-10"><a onClick={()=>{navigate("/product-details")}}>{product.product_name}</a></h3>
                                                    <div className="product__list--items__price mb-10">
                                                        <span className="current__price"><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {product.onlinerate}</span>
                                                        <span className="price__divided"></span>
                                                        <span className="old__price"><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {product.mrprate}</span>
                                                    </div>
                                                    <p className="product__list--items__content--desc mb-15">{product.description}</p>
                                                    {message && <div className="message">{message}</div>}
                                                    <ul className="product__items--action d-flex">
                                                    {cartItems.find(cartItem => cartItem.product_id === product.product_id) === undefined ? (
                                    <li className="product__items--action__list">
                                        <a className="product__items--action__btn add__to--cart">
                                            <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534"   onClick={() => addItem("products", product.product_id)}>
                                                <g transform="translate(0 0)">
                                                    <g>
                                                        <path data-name="Path 16787" d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z" transform="translate(0 -463.248)" fill="currentColor"></path>
                                                        <path data-name="Path 16788" d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z" transform="translate(-1.191 -466.622)" fill="currentColor"></path>
                                                        <path data-name="Path 16789" d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z" transform="translate(-2.875 -466.622)" fill="currentColor"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className="add__to--cart__text" onClick={() => addItem("products", product.product_id)}>
                                                + Add to Cart
                                            </span>
                                        </a>
                                    </li>
                                ) : (
                                    <li className="product__items--action__list">
                                        <a className="product__items--action__btn add__to--cart">
                                            <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534"  onClick={() => navigate("/cart")}>
                                                <g transform="translate(0 0)">
                                                    <g>
                                                        <path data-name="Path 16787" d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z" transform="translate(0 -463.248)" fill="currentColor"></path>
                                                        <path data-name="Path 16788" d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z" transform="translate(-1.191 -466.622)" fill="currentColor"></path>
                                                        <path data-name="Path 16789" d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z" transform="translate(-2.875 -466.622)" fill="currentColor"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className="add__to--cart__text" onClick={() => navigate("/cart")}>
                                                Show Cart
                                            </span>
                                        </a>
                                    </li>
                                )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pagination__area bg__gray--color">
      <nav className="pagination justify-content-center">
        <ul className="pagination__wrapper d-flex align-items-center justify-content-center">
          {/* Previous Arrow */}
          <li className="pagination__list">
            <a
              className="pagination__item--arrow link"
              onClick={() => handlePageChange(currentPage - 1)}
              style={{ cursor: 'pointer' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.51"
                height="20.443"
                viewBox="0 0 512 512"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48"
                  d="M244 400L100 256l144-144M120 256h292"
                />
              </svg>
              <span className="visually-hidden">Previous</span>
            </a>
          </li>

          {/* Page Numbers */}
          {[...Array(totalPages)].map((_, index) => {
            const pageNumber = index + 1;
            return (
              <li className="pagination__list" key={pageNumber}>
                {pageNumber === currentPage ? (
                  <span className="pagination__item pagination__item--current">
                    {pageNumber}
                  </span>
                ) : (
                  <a
                    className="pagination__item link"
                    onClick={() => handlePageChange(pageNumber)}
                    style={{ cursor: 'pointer' }}
                  >
                    {pageNumber}
                  </a>
                )}
              </li>
            );
          })}

          {/* Next Arrow */}
          <li className="pagination__list">
            <a
              className="pagination__item--arrow link"
              onClick={() => handlePageChange(currentPage + 1)}
              style={{ cursor: 'pointer' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.51"
                height="20.443"
                viewBox="0 0 512 512"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48"
                  d="M268 112l144 144-144 144M392 256H100"
                />
              </svg>
              <span className="visually-hidden">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>

                </div>
            </div>
        </div>
        </div>
      </section>
      {/* end broadcamp section */}
     </>
    )}
    </main>


  )
}

export default Shop