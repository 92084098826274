import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useNavigate, useLocation  } from 'react-router-dom'
import { CartContext } from '../context/CartContext';
import axios from 'axios';

const Header = ({ cartOpen, setCartOpen, menuOpen, setMenuOpen }) => {
    const { cartCount, setCartCount, cartItems } = useContext(CartContext);
    const navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [docHeight, setDocHeight] = useState(document.documentElement.scrollHeight);
    const [offcanvasSearch, setOffcanvasSearch] = useState(false);
    const [categories, setCategories] = useState([]);
    const [contactData, setContactData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const userId = localStorage.getItem("userId");
    const [searchTerm, setSearchTerm] = useState("");
    const userName = localStorage.getItem('userName');
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('1'); // State for selected subcategory, default to "All Categories"


    const handleSearchSubmit = (e) => {
      e.preventDefault(); // Prevent page refresh
    
      // Build the query parameters for the URL
      const params = new URLSearchParams();
    
      // If a subcategory is selected, add it to the query parameters
      if (selectedSubCategory && selectedSubCategory !== "1") {
        params.append("subcategory_id", selectedSubCategory); // Use "subcategory_id" for consistency
      }
    
      // If there is a search term, add it to the query parameters
      if (searchTerm.trim()) {
        params.append("search", encodeURIComponent(searchTerm.trim()));
      }
    
      // Navigate to the shop page with the constructed query parameters
      navigate(`/shop?${params.toString()}`);
    };
    


      useEffect(() => {
        axios
          .post('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/all_subcategory.php')
          .then((response) => {
            if (response.data.head.code === 200) {
              setSubCategories(response.data.body.all_sub_category); // Update state with subcategory data
            }
          })
          .catch((error) => {
            console.error("There was an error fetching the subcategories!", error);
          });
      }, []);



    // Fetch data from API
    useEffect(() => {
      fetch('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/subcategory.php', {
        method: 'POST',
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.head.code === 200) {
            setCategories(data.body.category);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }, []);
  
    // Navigate to shop page with subcategory ID
    const handleSubcategoryClick = (subcategoryId) => {
      navigate(`/shop?subcategory_id=${subcategoryId}`);
    //   console.log(subcategoryId)
    };
    
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        const handleResize = () => {
            setDocHeight(document.documentElement.scrollHeight);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Cleanup the event listeners on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /* -------------contact data api connection--------------- */

 
    useEffect(() => {
        const fetchContactData = async () => {
          try {
            console.log('Fetching contact data...');
            const response = await axios.post('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/content.php');

            console.log('Response received:', response.data); 
      
            // Access the contact data correctly
            if (response.data && response.data.body && response.data.body.contact.contact && Array.isArray(response.data.body.contact.contact)) {
              setContactData(response.data.body.contact.contact); 

              console.log('Contact Data:', response.data.body.contact);
    
            } else {
              setError('Failed to load contact data.');
            }
          } catch (err) {
            console.error('Error fetching data:', err);
            setError('Failed to load contact data.');
          } finally {
            setLoading(false);
          }
        };
      
        fetchContactData();
      }, []);

    return (
        <header className="header__section">
            <div className="header__topbar bg__secondary">
                <div className="container-fluid">
                {Array.isArray(contactData) && contactData.length > 0 ? (
                   contactData.map((contact, index) => (
                    <div className="header__topbar--inner d-flex align-items-center justify-content-between" key={index}>
                        <div className="header__shipping">
                            <ul className="header__shipping--wrapper d-flex">
                                <li className="header__shipping--text text-white">Welcome to Vedanth Fashions</li>
                                <li className="header__shipping--text text-white d-sm-2-none"><img className="header__shipping--text__icon" src="assets/img/icon/bus.png" alt="bus-icon" /> Track Your Order</li>
                                <li className="header__shipping--text text-white d-sm-2-none"><img className="header__shipping--text__icon" src="assets/img/icon/email.png" alt="email-icon" /> <a className="header__shipping--text__link" href="mailto:demo@gmail.com"> {contact.email}</a></li>
                            </ul>
                        </div>
                        <div className="language__currency d-none d-lg-block">
                            <ul className="d-flex align-items-center">
                                <li className="language__currency--list">
                                    <li className="header__shipping--text text-white d-sm-2-none"><i class="bi bi-phone"></i> Phone Number : {contact.phonenumber}</li>

                                </li>
                                <li className="language__currency--list">
                                    <li className="header__shipping--text text-white d-sm-2-none"><i class="bi bi-whatsapp"></i> Whatsapp : {contact.whatsapp}</li>

                                </li>
                            </ul>
                        </div>
                    </div>
                ))
            ) : (
              <p>No contacts available</p> // Fallback message
            )}
                </div>
            </div>
            <div className={`main__header header__sticky ${scrollPosition >= 127 ? "sticky" : ""}`}>
                <div className="container-fluid">
                    <div className="main__header--inner position__relative d-flex justify-content-between align-items-center">
                        <div className="offcanvas__header--menu__open ">
                            <a className="offcanvas__header--menu__open--btn" href="javascript:void(0)" data-offcanvas onClick={() => { setMenuOpen(cur => !cur) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon offcanvas__header--menu__open--svg" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 160h352M80 256h352M80 352h352" /></svg>
                                <span className="visually-hidden">Menu Open</span>
                            </a>
                        </div>
                        <div className="main__logo">
                            <h1 className="main__logo--title"><a className="main__logo--link" onClick={() => { navigate("/") }}><img className="main__logo--img" src="assets/img/logo.png" alt="logo-img" /></a></h1>
                        </div>
                          <div className="header__search--widget header__sticky--none d-none d-lg-block">
                          
  <form className="d-flex header__search--form" onSubmit={handleSearchSubmit}>
    <div className="header__select--categories select">
      <select
        className="header__select--inner"
        value={selectedSubCategory} // Add state for selected subcategory
        onChange={(e) => setSelectedSubCategory(e.target.value)} // Update selected subcategory
      >
        <option value="1">All Categories</option>
        {/* Map over subCategories to display them in the dropdown */}
        {subCategories.map((subcategory) => (
          <option key={subcategory.subcategory_id} value={subcategory.subcategory_id}>
            {subcategory.subcategory_name}
          </option>
        ))}
      </select>
    </div>
    <div className="header__search--box">
      <label>
        <input
          className="header__search--input"
          placeholder="Search here..."
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search input state
        />
      </label>
      <button
        className="header__search--button bg__secondary text-white"
        type="submit"
        aria-label="search button"
      >
        <svg
          className="header__search--button__svg"
          xmlns="http://www.w3.org/2000/svg"
          width="27.51"
          height="26.443"
          viewBox="0 0 512 512"
        >
          <path
            d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            strokeWidth="32"
          ></path>
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="32"
            d="M338.29 338.29L448 448"
          ></path>
        </svg>
      </button>
    </div>
  </form>
</div>

                        <div className="header__account header__sticky--none">
                            <ul className="d-flex">
                                <li className="header__account--items">
                                    <a className="header__account--btn" onClick={() => { navigate('/account') }}>
                                        {/* <Link to={'/account'}> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443" viewBox="0 0 512 512"><path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /></svg>
                                        
                                        {userId ? (
                                          <p style={{marginTop:"10px"}} className='username'>{userName.charAt(0).toUpperCase() + userName.slice(1)}</p>
                                        ):(
                                          <span className="header__account--btn__text">My Account</span>
                                        )}
                                        

                                        {/* </Link> */}
                                    </a>
                                </li>

                                <li className="header__account--items carticonmobile">
                                    <a className="header__account--btn minicart__open--btn" href="javascript:void(0)" data-offcanvas onClick={() => { setCartOpen(curr => !curr) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443" viewBox="0 0 14.706 13.534">
                                            <g transform="translate(0 0)">
                                                <g >
                                                    <path data-name="Path 16787" d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z" transform="translate(0 -463.248)" fill="currentColor" />
                                                    <path data-name="Path 16788" d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z" transform="translate(-1.191 -466.622)" fill="currentColor" />
                                                    <path data-name="Path 16789" d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z" transform="translate(-2.875 -466.622)" fill="currentColor" />
                                                </g>
                                            </g>
                                        </svg>
                                        <span className="header__account--btn__text"> My cart</span>
                                        <span className="items__count">{cartCount}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>



                        <div className="header__menu d-none header__sticky--block d-lg-block">
      <nav className="header__menu--navigation">
        <ul className="d-flex">
          <li className="header__menu--items style2">
            <a className="header__menu--link">
              <Link to="/">Home</Link>
            </a>
          </li>
          <li className="header__menu--items style2">
            <a className="header__menu--link">
              <Link to="/about">About Us</Link>
            </a>
          </li>

          {/* Loop through categories */}
          {categories.map((category) => (
            <li key={category.category_id} className="header__menu--items style2">
              <a className="header__menu--link">
                {category.category_name}
                <svg
                  className="menu__arrowdown--icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="7.41"
                  viewBox="0 0 12 7.41"
                >
                  <path
                    d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z"
                    transform="translate(-6 -8.59)"
                    fill="currentColor"
                    opacity="0.7"
                  />
                </svg>
              </a>
              <ul className="header__sub--menu">
                {/* Loop through subcategories */}
                {category.subcategory.map((subcategory) => (
                  <li key={subcategory.subcategory_id} className="header__sub--menu__items">
                    <a
                      className="header__sub--menu__link"
                      onClick={() => handleSubcategoryClick(subcategory.subcategory_id)}
                    >
                      {subcategory.subcategory_name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          ))}

          <li className="header__menu--items style2">
            <a className="header__menu--link">
              <Link to="/contact">Contact Us</Link>
            </a>
          </li>
          {userId ? (
             <li className="header__menu--items">
             <a className="header__menu--link"
               onClick={() => {
                window.alert("You have successfully logged out!"); // Show alert
                 localStorage.removeItem("userId");
                 navigate("/");
               }}
             >
               Log Out
             </a>
           </li>
          
          ):(
            <li className="header__menu--items ">
            <a className="header__menu--link">
              <Link to="/login">Login/Register</Link>
            </a>
          </li>
          )}
        </ul>
      </nav>
    </div>
                        <div className="header__account header__account2 header__sticky--block">
                            <ul className="d-flex">
                                <li className="header__account--items header__account2--items  header__account--search__items d-none d-lg-block" onClick={() => { setOffcanvasSearch(true) }}>
                                    <a className="header__account--btn search__open--btn" href="javascript:void(0)" data-offcanvas>
                                        <svg className="header__search--button__svg" xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448" /></svg>
                                        <span className="visually-hidden" >Search</span>
                                    </a>
                                </li>
                                <li className="header__account--items header__account2--items">
                                    <a className="header__account--btn" onclick={() => { navigate("/account") }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443" viewBox="0 0 512 512"><path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /></svg>
                                        {userId ? (
                                        <p style={{marginTop:"10px"}}>{userName.charAt(0).toUpperCase() + userName.slice(1)} </p>
                                        ):(
                                          <span className="visually-hidden" >My Account</span>
                                        )}
                                    </a>
                                </li>
     
                                <li className="header__account--items header__account2--items carticonmobile">
                                    <a className="header__account--btn minicart__open--btn" href="javascript:void(0)" data-offcanvas onClick={() => { setCartOpen(curr => !curr) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443" viewBox="0 0 14.706 13.534">
                                            <g transform="translate(0 0)">
                                                <g >
                                                    <path data-name="Path 16787" d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z" transform="translate(0 -463.248)" fill="currentColor" />
                                                    <path data-name="Path 16788" d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z" transform="translate(-1.191 -466.622)" fill="currentColor" />
                                                    <path data-name="Path 16789" d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z" transform="translate(-2.875 -466.622)" fill="currentColor" />
                                                </g>
                                            </g>
                                        </svg>
                                        <span className="items__count style2">{cartCount}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        
                       
                        {/* header bottom  */}

            <div className="header__bottom">
                <div className="container-fluid">
                    <div className="header__bottom--inner position__relative d-none d-lg-flex justify-content-between align-items-center">
                        <div className="header__menu">
                            <nav className="header__menu--navigation">
                            <ul className="d-flex">
          <li className="header__menu--items style2">
            <a className="header__menu--link">
              <Link to="/">Home</Link>
            </a>
          </li>
          <li className="header__menu--items">
            <a className="header__menu--link">
              <Link to="/about">About Us</Link>
            </a>
          </li>

          {/* Loop through categories */}
          {categories.map((category) => (
            <li key={category.category_id} className="header__menu--items">
              <a className="header__menu--link">
                {category.category_name}
                <svg
                  className="menu__arrowdown--icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="7.41"
                  viewBox="0 0 12 7.41"
                >
                  <path
                    d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z"
                    transform="translate(-6 -8.59)"
                    fill="currentColor"
                    opacity="0.7"
                  />
                </svg>
              </a>
              <ul className="header__sub--menu">
                {/* Loop through subcategories */}
                {category.subcategory.map((subcategory) => (
                  <li key={subcategory.subcategory_id} className="header__sub--menu__items">
                    <a
                      className="header__sub--menu__link"
                      onClick={() => handleSubcategoryClick(subcategory.subcategory_id)}
                    >
                      {subcategory.subcategory_name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          ))}

          <li className="header__menu--items ">
            <a className="header__menu--link">
              <Link to="/contact">Contact Us</Link>
            </a>
          </li>
          {userId ? (
             <li className="header__menu--items">
             <a className="header__menu--link"
               onClick={() => {
                window.alert("You have successfully logged out!"); // Show alert
                 localStorage.removeItem("userId");
                 navigate("/");
               }}
             >
               Log Out
             </a>
           </li>
          
          ):(
            <li className="header__menu--items ">
            <a className="header__menu--link">
              <Link to="/login">Login/Register</Link>
            </a>
          </li>
          )}
        </ul>
                            </nav>
                        </div>
                        <p className="header__discount--text"><img className="header__discount--icon__img" src="assets/img/icon/lamp.png" alt="lamp-img" /> Vedanth Fashions</p>
                    </div>
                </div>
            </div>




            {/* <!-- Start Offcanvas header menu  DUMMY DATA --> */}
            <div className="offcanvas__header">
                <div className="offcanvas__inner">
                    <div className="offcanvas__logo">
                        <a className="offcanvas__logo_link" onClick={() => { navigate("/") }}>
                            <img src="assets/img/logo.png" alt="Manjal Silks Logo" width="158" height="36" />
                        </a>
                        <button className="offcanvas__close--btn" data-offcanvas>close</button>
                    </div>
                    <nav className="offcanvas__menu">
                        <ul className="offcanvas__menu_ul">
                            <li className="offcanvas__menu_li">
                                <a className="offcanvas__menu_item" onClick={() => { navigate("/") }}>Home</a>

                            </li>
                            <li className="offcanvas__menu_li"><a className="offcanvas__menu_item" onClick={() => { navigate("/about") }}>About Us</a></li>
                            <li className="offcanvas__menu_li">
                                <a className="offcanvas__menu_item" onClick={() => { navigate("/shop") }}>Collections of Sarees</a>
                                <ul className="offcanvas__sub_menu">
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Grid</a></li>
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Details</a></li>
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Left Sidebar</a></li>
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Right Sidebar</a></li>
                                </ul>
                            </li>
                            <li className="offcanvas__menu_li">
                                <a className="offcanvas__menu_item" onClick={() => { navigate("/shop") }}>Blouse Designs</a>
                                <ul className="offcanvas__sub_menu">
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Grid</a></li>
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Details</a></li>
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Left Sidebar</a></li>
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Right Sidebar</a></li>
                                </ul>
                            </li>
                            <li className="offcanvas__menu_li">
                                <a className="offcanvas__menu_item" onClick={() => { navigate("/shop") }}>Chudithar Collections</a>
                                <ul className="offcanvas__sub_menu">
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Grid</a></li>
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Details</a></li>
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Left Sidebar</a></li>
                                    <li className="offcanvas__sub_menu_li"><a className="offcanvas__sub_menu_item" onClick={() => { navigate("/shop") }}>Blog Right Sidebar</a></li>
                                </ul>
                            </li>
                            <li className="offcanvas__menu_li"><a className="offcanvas__menu_item" onClick={() => { navigate("/contact") }}>Contact Us</a></li>
                        </ul>
                        <div className="offcanvas__account--items">
                            <a className="offcanvas__account--items__btn d-flex align-items-center" onClick={() => { navigate("/login") }}>
                                <span className="offcanvas__account--items__icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.51" height="19.443" viewBox="0 0 512 512"><path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /></svg>
                                </span>
                                <span className="offcanvas__account--items__label">Login / Register</span>
                            </a>
                        </div>

                    </nav>
                </div>
            </div>
            {/* <!-- End Offcanvas header menu  --> */}

            {/* <!-- Start Offcanvas stikcy bottom toolbar --> */}
            <div className="offcanvas__stikcy--toolbar">
                <ul className="d-flex justify-content-between">
                    <li className="offcanvas__stikcy--toolbar__list">
                        <a className="offcanvas__stikcy--toolbar__btn" onClick={() => { navigate("/") }}>
                            <span className="offcanvas__stikcy--toolbar__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="21.51" height="21.443" viewBox="0 0 22 17"><path fill="currentColor" d="M20.9141 7.93359c.1406.11719.2109.26953.2109.45703 0 .14063-.0469.25782-.1406.35157l-.3516.42187c-.1172.14063-.2578.21094-.4219.21094-.1406 0-.2578-.04688-.3515-.14062l-.9844-.77344V15c0 .3047-.1172.5625-.3516.7734-.2109.2344-.4687.3516-.7734.3516h-4.5c-.3047 0-.5742-.1172-.8086-.3516-.2109-.2109-.3164-.4687-.3164-.7734v-3.6562h-2.25V15c0 .3047-.11719.5625-.35156.7734-.21094.2344-.46875.3516-.77344.3516h-4.5c-.30469 0-.57422-.1172-.80859-.3516-.21094-.2109-.31641-.4687-.31641-.7734V8.46094l-.94922.77344c-.11719.09374-.24609.14062-.38672.14062-.16406 0-.30468-.07031-.42187-.21094l-.35157-.42187C.921875 8.625.875 8.50781.875 8.39062c0-.1875.070312-.33984.21094-.45703L9.73438.832031C10.1094.527344 10.5312.375 11 .375s.8906.152344 1.2656.457031l8.6485 7.101559zm-3.7266 6.50391V7.05469L11 1.99219l-6.1875 5.0625v7.38281h3.375v-3.6563c0-.3046.10547-.5624.31641-.7734.23437-.23436.5039-.35155.80859-.35155h3.375c.3047 0 .5625.11719.7734.35155.2344.211.3516.4688.3516.7734v3.6563h3.375z"></path></svg>
                            </span>
                            <span className="offcanvas__stikcy--toolbar__label">Home</span>
                        </a>
                    </li>
                    {/* <li className="offcanvas__stikcy--toolbar__list">
                        <a className="offcanvas__stikcy--toolbar__btn" onClick={() => { navigate("/shop") }} >
                            <span className="offcanvas__stikcy--toolbar__icon">
                                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="18.51" height="17.443" viewBox="0 0 448 512"><path d="M416 32H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-16 48v152H248V80zm-200 0v152H48V80zM48 432V280h152v152zm200 0V280h152v152z"></path></svg>
                            </span>
                            <span className="offcanvas__stikcy--toolbar__label">Shop</span>
                        </a>
                    </li> */}
                    <li className="offcanvas__stikcy--toolbar__list ">
                        <a className="offcanvas__stikcy--toolbar__btn search__open--btn" href="javascript:void(0)" data-offcanvas onClick={() => { setOffcanvasSearch(true) }}>
                            <span className="offcanvas__stikcy--toolbar__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448" /></svg>
                            </span>
                            <span className="offcanvas__stikcy--toolbar__label">Search</span>
                        </a>
                    </li>
                    <li className="offcanvas__stikcy--toolbar__list">
                        <a className="offcanvas__stikcy--toolbar__btn minicart__open--btn" href="javascript:void(0)" data-offcanvas onClick={() => { navigate("/cart") }}>
                            <span className="offcanvas__stikcy--toolbar__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.51" height="15.443" viewBox="0 0 18.51 15.443">
                                    <path d="M79.963,138.379l-13.358,0-.56-1.927a.871.871,0,0,0-.6-.592l-1.961-.529a.91.91,0,0,0-.226-.03.864.864,0,0,0-.226,1.7l1.491.4,3.026,10.919a1.277,1.277,0,1,0,1.844,1.144.358.358,0,0,0,0-.049h6.163c0,.017,0,.034,0,.049a1.277,1.277,0,1,0,1.434-1.267c-1.531-.247-7.783-.55-7.783-.55l-.205-.8h7.8a.9.9,0,0,0,.863-.651l1.688-5.943h.62a.936.936,0,1,0,0-1.872Zm-9.934,6.474H68.568c-.04,0-.1.008-.125-.085-.034-.118-.082-.283-.082-.283l-1.146-4.037a.061.061,0,0,1,.011-.057.064.064,0,0,1,.053-.025h1.777a.064.064,0,0,1,.063.051l.969,4.34,0,.013a.058.058,0,0,1,0,.019A.063.063,0,0,1,70.03,144.853Zm3.731-4.41-.789,4.359a.066.066,0,0,1-.063.051h-1.1a.064.064,0,0,1-.063-.051l-.789-4.357a.064.064,0,0,1,.013-.055.07.07,0,0,1,.051-.025H73.7a.06.06,0,0,1,.051.025A.064.064,0,0,1,73.76,140.443Zm3.737,0L76.26,144.8a.068.068,0,0,1-.063.049H74.684a.063.063,0,0,1-.051-.025.064.064,0,0,1-.013-.055l.973-4.357a.066.066,0,0,1,.063-.051h1.777a.071.071,0,0,1,.053.025A.076.076,0,0,1,77.5,140.448Z" transform="translate(-62.393 -135.3)" fill="currentColor" />
                                </svg>
                            </span>
                            <span className="offcanvas__stikcy--toolbar__label">Cart</span>
                            <span className="items__count">{cartCount}</span>
                        </a>
                    </li>
                    <li className="offcanvas__stikcy--toolbar__list">
                        <a className="offcanvas__stikcy--toolbar__btn" onClick={() => { navigate("/contact") }}>
                            <span className="offcanvas__stikcy--toolbar__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="30" viewBox="0 0 30.326 33.112">
                                    <g id="support" transform="translate(-17.756)">
                                        <g id="Group_118" data-name="Group 118" transform="translate(17.756)">
                                            <path id="Path_81" data-name="Path 81" d="M44.886,13.687H44.8V11.679a11.679,11.679,0,0,0-23.359,0v2.008h-.492a3.337,3.337,0,0,0-3.2,3.442v5.122a3.237,3.237,0,0,0,3.154,3.319h3.075a.779.779,0,0,0,.741-.814c0-.015,0-.031,0-.046V14.671c0-.492-.287-.984-.738-.984h-.9V11.679a10.04,10.04,0,0,1,20.08,0v2.008h-.9c-.451,0-.738.492-.738.984v10.04a.779.779,0,0,0,.691.857l.046,0h.943l-.082.123a6.475,6.475,0,0,1-5.2,2.582,4.057,4.057,0,0,0-8.032.779,4.1,4.1,0,0,0,4.1,4.057,4.221,4.221,0,0,0,2.951-1.27,3.606,3.606,0,0,0,.984-1.967,8.114,8.114,0,0,0,6.516-3.237l.779-1.147a2.79,2.79,0,0,0,2.869-2.828V17.539C48.083,15.777,46.771,13.687,44.886,13.687Zm-21.8,10.245H20.954A1.6,1.6,0,0,1,19.4,22.3c0-.015,0-.029,0-.044V17.13a1.7,1.7,0,0,1,1.557-1.8h2.131Zm12.663,6.762a2.418,2.418,0,0,1-1.762.779,2.5,2.5,0,0,1-2.459-2.418,2.418,2.418,0,1,1,4.836,0v0A2.172,2.172,0,0,1,35.748,30.694Zm10.7-8.032c0,1.106-1.065,1.27-1.557,1.27H43.165V15.326h1.721c.9,0,1.557,1.27,1.557,2.213Z" transform="translate(-17.756)" fill="currentColor" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <span className="offcanvas__stikcy--toolbar__label">Contact</span>
                        </a>
                    </li>
                </ul>
            </div>
            {/* <!-- End Offcanvas stikcy toolbar --> */}

            {/* <!-- Start offCanvas minicart --> */}
            {/* <div className="offCanvas__minicart">
                <div className="minicart__header ">
                    <div className="minicart__header--top d-flex justify-content-between align-items-center">
                        <h2 className="minicart__title h3"> Shopping Cart</h2>
                        <button className="minicart__close--btn" aria-label="minicart close button" data-offcanvas>
                            <svg className="minicart__close--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368" /></svg>
                        </button>
                    </div>
                    <p className="minicart__header--desc">Clothing and fashion products are limited</p>
                </div>
                <div className="minicart__product">
                    <div className="minicart__product--items d-flex">
                        <div className="minicart__thumb">
                            <a onClick={() => { navigate("/product-details") }}><img src="assets/img/product/product1.jpg" alt="prduct-img" /></a>
                        </div>
                        <div className="minicart__text">
                            <h3 className="minicart__subtitle h4"><a onClick={() => { navigate("/product-details") }}>Oversize Cotton Dress</a></h3>
                            <span className="color__variant"><b>Color:</b> Beige</span>
                            <div className="minicart__price">
                                <span className="current__price"><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i>  125.00</span>
                                <span className="old__price"><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i>  140.00</span>
                            </div>
                            <div className="minicart__text--footer d-flex align-items-center">
                                <div className="quantity__box minicart__quantity">
                                    <button type="button" className="quantity__value decrease" aria-label="quantity value" value="Decrease Value">-</button>
                                    <label>
                                        <input type="number" className="quantity__number" value="1" data-counter />
                                    </label>
                                    <button type="button" className="quantity__value increase" value="Increase Value">+</button>
                                </div>
                                <button className="minicart__product--remove">Remove</button>
                            </div>
                        </div>
                    </div>
                    <div className="minicart__product--items d-flex">
                        <div className="minicart__thumb">
                            <a onClick={() => { navigate("/product-details") }}><img src="assets/img/product/product2.jpg" alt="prduct-img" /></a>
                        </div>
                        <div className="minicart__text">
                            <h3 className="minicart__subtitle h4"><a onClick={() => { navigate("/product-details") }}>Boxy Denim Jacket</a></h3>
                            <span className="color__variant"><b>Color:</b> Green</span>
                            <div className="minicart__price">
                                <span className="current__price"><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i>  115.00</span>
                                <span className="old__price"><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i>  130.00</span>
                            </div>
                            <div className="minicart__text--footer d-flex align-items-center">
                                <div className="quantity__box minicart__quantity">
                                    <button type="button" className="quantity__value decrease" aria-label="quantity value" value="Decrease Value">-</button>
                                    <label>
                                        <input type="number" className="quantity__number" value="1" data-counter />
                                    </label>
                                    <button type="button" className="quantity__value increase" aria-label="quantity value" value="Increase Value">+</button>
                                </div>
                                <button className="minicart__product--remove">Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="minicart__amount">
                    <div className="minicart__amount_list d-flex justify-content-between">
                        <span>Sub Total:</span>
                        <span><b><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i>  240.00</b></span>
                    </div>
                    <div className="minicart__amount_list d-flex justify-content-between">
                        <span>Total:</span>
                        <span><b><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i>  240.00</b></span>
                    </div>
                </div>
                <div className="minicart__conditions text-center">
                    <input className="minicart__conditions--input" id="accept" type="checkbox" />
                    <label className="minicart__conditions--label" for="accept">I agree with the <a className="minicart__conditions--link" onclick={() => { navigate("/privacypolicy") }}>Privacy and Policy</a></label>
                </div>
                <div className="minicart__button d-flex justify-content-center">
                    <a className="primary__btn minicart__button--link" onclick={() => { navigate("/cart") }}>View cart</a>
                    <a className="primary__btn minicart__button--link" onclick={() => { navigate("/checkout") }}>Checkout</a>
                </div>
            </div> */}
            {/* <!-- End offCanvas minicart --> */}

            {/* <!-- Start serch box area  popup  small screen --> */}
            <div className={`predictive__search--box ${offcanvasSearch === true ? "active" : ""}`}>
                <div className="predictive__search--box__inner">
                    <h2 className="predictive__search--title">Search Products</h2>
                    <form className="d-flex header__search--form" action="#"  onSubmit={handleSearchSubmit}>
                                
                                <div className="header__search--box">
                                <label>
            <input
              className="header__search--input"
              placeholder="Search here..."
              type="text"
              value={searchTerm} // Bind the search term state
              onChange={(e) => setSearchTerm(e.target.value)} // Update state on input change
            />
          </label>
          <button
            className="header__search--button bg__secondary text-white"
            type="submit"
            aria-label="search button"
          >
            <svg className="header__search--button__svg" xmlns="http://www.w3.org/2000/svg" width="27.51" height="26.443" viewBox="0 0 512 512">
              <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></path>
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448"></path>
            </svg>
          </button>
            </div>
         </form>
        </div>
                <button className="predictive__search--close__btn" aria-label="search close button" data-offcanvas onClick={() => { setOffcanvasSearch(false) }}>
                    <svg className="predictive__search--close__icon" xmlns="http://www.w3.org/2000/svg" width="40.51" height="30.443" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368" /></svg>
                </button>
            </div>
            {/* <!-- End serch box area --> */}

        </header>
    )
}

export default Header