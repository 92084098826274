import React, { createContext, useState, useEffect } from 'react';

// Create the context
const CartContext = createContext();

// Create a provider component
const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);

  // Function to add an item to the cart
  const addItemToCart = (item) => {
    const itemExists = cartItems.find(
      cartItem => cartItem.product_id === item.product_id && cartItem.image === item.image
    );
  
    if (!itemExists) {
      setCartItems([
        ...cartItems,
        {
          ...item,
          qty: 1,
          tot: item.onlinerate,
          main_category_id: item.maincategory_id, // Use item value directly
          sub_category_id: item.subcategory_id      // Use item value directly
        }
      ]);
    } else {
      setCartItems(cartItems.map(cartItem =>
        cartItem.product_id === item.product_id && cartItem.image === item.image
          ? {
              ...cartItem,
              qty: cartItem.qty + 1,
              tot: item.onlinerate * (cartItem.qty + 1)
            }
          : cartItem
      ));
    }
  };
  
  
  

  // Function to remove an item from the cart based on both product_id and image
const removeItemFromCart = (product_id, image) => {
  setCartItems(prevItems => 
    prevItems.filter(item => !(item.product_id === product_id && item.image === image))
  );
};


  // Update cart count whenever cartItems change
  useEffect(() => {
    // Count unique products in the cart
    const uniqueItemCount = cartItems.length;
    setCartCount(uniqueItemCount);
  }, [cartItems]);

  return (
    <CartContext.Provider value={{ cartItems, cartCount, addItemToCart, removeItemFromCart,setCartItems }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
