import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { CartContext } from '../context/CartContext';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Spinner from 'react-bootstrap/Spinner';

const CheckOut = () => {

  const navigate = useNavigate();
  const { cartItems, addItemToCart, removeItemFromCart } = useContext(CartContext);
  const{handleOutsideClick}=useOutletContext();
  const userId = localStorage.getItem('userId');
  //console.log("userid" + userId )
  //sub Total current Price
  const [loading, setLoading] = useState(false); // Loader state for submit button
  const subtotalCurrentPrice = cartItems.reduce((acc, item) => acc + item.onlinerate * item.qty, 0);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [editMode, setEditMode] = useState(false); // Add edit mode state
  const [errorMessage, setErrorMessage] = useState(null);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [gstError, setGstError] = useState(null);
  const [note, setNote] = useState('');
  const [deliveryDetails, setDeliveryDetails] = useState({
    delivery_name: '',
    delivery_lastname: '', // Added lastname optional
    delivery_address: '',
    delivery_city: '',
    delivery_state: '',
    delivery_email: '',
    delivery_phonenumber: '',
    delivery_postalcode: ''
});


  // Function to handle textarea input
  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

/*------------------------------ Start Address list api connection--------------------------- */

  useEffect(() => {
    fetchAddresses();
}, [userId]);


const fetchAddresses = async () => {
    if (!userId) {
        setErrorMessage('User ID not found');
        return;
    }

    try {
        const response = await fetch('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/addresslist.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: userId, // Ensure userId is valid
            }),
        });

        // Check if the response is okay
        console.log('API Response Status:', response.status); // Log response status

        if (response.ok) {
            const data = await response.json();
            console.log('API Response:', data); // Debugging the API response

            if (data.head.code === 200) {
                setAddresses(data.body); // Store the addresses in state
            } else {
                setErrorMessage('Failed to fetch addresses: ' + data.head.msg);
            }
        } else {
            setErrorMessage('Failed to fetch addresses: ' + response.status);
        }
    } catch (error) {
        console.error('Error fetching addresses:', error);
        setErrorMessage('An error occurred while fetching addresses');
    }
};


  /* const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
      const response = await axios.post('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/addresslistadd.php', {
        user_id: userId, // Assuming userId is passed as a prop
        name: deliveryDetails.delivery_name,
        address: deliveryDetails.delivery_address,
        city: deliveryDetails.delivery_city,
        pincode: deliveryDetails.delivery_postalcode,
        state: deliveryDetails.delivery_state,
        landmark: deliveryDetails.delivery_landmark,
        label: deliveryDetails.delivery_label,
        phonenumber: deliveryDetails.delivery_phonenumber
      });
      console.log('Address added successfully:', response.data);
      // Optionally, reset the form or perform additional actions
      toast.success('Address added successfully!', {
        onClose: () => {
          // Reset the form when the toast is closed
          setDeliveryDetails({
            delivery_name: '',
            delivery_lastname: '',
            delivery_address: '',
            delivery_city: '',
            delivery_state: '',
            delivery_email: '',
            delivery_landmark: '',
            delivery_label: '',
            delivery_postalcode: '',
            delivery_phonenumber: ''
          });
          setIsFormVisible(false); // Hide the form after submission
        }
      });
    } catch (error) {
      console.error('Error adding address:', error);
    }
  }; */

/*------------------------------Cgst, Sgst get the list api connection--------------------------- */

useEffect(() => {
  // Fetch GST data from the API
  axios.post('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/gst_percentage.php')
    .then(response => {
      if (response.data.head.code === 200) {
        const gstData = response.data.body.gst[0];
        setCgst(parseFloat(gstData.cgst));
        setSgst(parseFloat(gstData.sgst));
      } else {
        setGstError('Failed to fetch GST data');
      }
    })
    .catch(error => {
      setGstError('Error fetching GST data');
    });
}, []);

// Calculate GST and grand total
const cgstAmount = (subtotalCurrentPrice * cgst) / 100;
const sgstAmount = (subtotalCurrentPrice * sgst) / 100;
const grandTotal = subtotalCurrentPrice + cgstAmount + sgstAmount;

  console.log("Subtotal:", subtotalCurrentPrice);
console.log("SGST:", sgst);
console.log("CGST:", cgst);
console.log("Cart Items:", cartItems);

console.log("Delivery Details:", deliveryDetails);


{/*------------------------------Order send to order.php api connection--------------------------- */}

const handleOrderNow = async (event) => {
  event.preventDefault();

  // Check if an address is selected
  if (!selectedAddressId) {
    alert("Please select a delivery address.");
    return;
  }

  console.log("Cart Items:", JSON.stringify(cartItems, null, 2));

  // Optionally, fetch the selected address details if needed
  const selectedAddress = addresses.find(address => address.address_id === selectedAddressId);
  setLoading(true);
  // Build order details
  const orderDetails = {
    user_id: userId, // Assuming static for now
    delivery_details: selectedAddress ? {
      delivery_name: selectedAddress.name,
      delivery_address: selectedAddress.address,
      delivery_city: selectedAddress.city,
      delivery_state: selectedAddress.state,
      delivery_postalcode: selectedAddress.pincode,
      delivery_landmark: selectedAddress.landmark,
      delivery_label: selectedAddress.label,
      delivery_phonenumber: selectedAddress.phonenumber
    } : {}, // Include selected address details
    note: note,
    subtotal: subtotalCurrentPrice.toString(),
    sgst: sgstAmount.toString(),
    cgst: cgstAmount.toString(),
    total: grandTotal.toString(),
    productdetails: cartItems.map(item => ({
       main_category_id: item.main_category_id || 'N/A',  // Ensure this field exists
      sub_category_id: item.sub_category_id || 'N/A',
      image: item.image,
      product_id: item.product_id,
      product_name: item.product_name,
      qty: item.qty.toString(),
      amount: item.onlinerate.toString(),
      totalamount: (item.onlinerate * item.qty).toString()
    })),
  };

  // Log orderDetails before sending
  console.log("Order Details Before Sending:", JSON.stringify(orderDetails, null, 2));

  try {
    // Send order
    const response = await axios.post('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/order.php', orderDetails);

    console.log("Response Data:", response.data);  // Log the complete response

    if (response.data.head.code === 200) {
      alert(`Order placed successfully! Your order ID is: ${response.data.body.order_id}`);
      navigate("/account");
    } 
  } catch (error) {
    console.error("Error placing order:", error.response ? error.response.data : error.message);
    alert("There was an error placing your order. Please try again.");
  }finally {
    setLoading(false); // Hide loader
  }
};


useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  {/*----- Edit Addrss in View the Same add address form for editing purpose ----- */}


 // Handle address edit
 const handleEditAddress = (address) => {
  setDeliveryDetails({
    delivery_name: address.name,
    delivery_address: address.address,
    delivery_city: address.city,
    delivery_state: address.state,
    delivery_postalcode: address.pincode,
    delivery_landmark: address.landmark,
    delivery_label: address.label,
    delivery_phonenumber: address.phonenumber,
  });
  setSelectedAddressId(address.address_id);
  setEditMode(true); // Switch to edit mode
  setIsFormVisible(true);
};

{/*------------------------------Delete address api connection--------------------------- */}


// Handle delete address
const handleDeleteAddress = async (addressId) => {
  try {
    await axios.post("https://www.vedanthfashions.com/vfadmin/vfcustomerapi/addresslistdelete.php", {
      user_id: userId,
      address_id: addressId,
    });
    // Refetch the updated address list
    fetchAddresses();
  } catch (error) {
    console.error("Error deleting address:", error);
  }
};


{/*------------------------------ Start Edit Address api connection  and Add New Address api conection --------------------------- */}


// Handle form submit or update
const handleFormSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  if (editMode) {
    // If in edit mode, update the address
    try {
      await axios.post("https://www.vedanthfashions.com/vfadmin/vfcustomerapi/addresslistupdate.php", {
        user_id: userId,
        address_id: selectedAddressId,
        name: deliveryDetails.delivery_name,
        address: deliveryDetails.delivery_address,
        city: deliveryDetails.delivery_city,
        state: deliveryDetails.delivery_state,
        pincode: deliveryDetails.delivery_postalcode,
        landmark: deliveryDetails.delivery_landmark,
        label: deliveryDetails.delivery_label,
        phonenumber: deliveryDetails.delivery_phonenumber,
      });
      // Refetch the updated address list
      fetchAddresses();
      setEditMode(false); // Exit edit mode
      setIsFormVisible(false);
    } catch (error) {
      console.error("Error updating address:", error);
    }
    finally {
      setLoading(false); // Ensure loader stops after the request
    }
  } else {
    try {
      const response = await axios.post('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/addresslistadd.php', {
        user_id: userId, // Assuming userId is passed as a prop
        name: deliveryDetails.delivery_name,
        address: deliveryDetails.delivery_address,
        city: deliveryDetails.delivery_city,
        pincode: deliveryDetails.delivery_postalcode,
        state: deliveryDetails.delivery_state,
        landmark: deliveryDetails.delivery_landmark,
        label: deliveryDetails.delivery_label,
        phonenumber: deliveryDetails.delivery_phonenumber
      });
      console.log('Address added successfully:', response.data);
      // Optionally, reset the form or perform additional actions
      toast.success('Address added successfully!', {
        onClose: () => {
          // Reset the form when the toast is closed
          setDeliveryDetails({
            delivery_name: '',
            delivery_lastname: '',
            delivery_address: '',
            delivery_city: '',
            delivery_state: '',
            delivery_email: '',
            delivery_landmark: '',
            delivery_label: '',
            delivery_postalcode: '',
            delivery_phonenumber: ''
          });
          setIsFormVisible(false); // Hide the form after submission
        }
      });
    } catch (error) {
      console.error('Error adding address:', error);
    }finally {
      setLoading(false); // Ensure loader stops after the request
    }
};
}
{/*------------------------------ End Edit Address api connection and Add New Address api conection --------------------------- */}


  return (
    <main class="main__content_wrapper" onClick={handleOutsideClick}>
      {/* <!-- Start breadcrumb section --> */}
      <section class="breadcrumb__section breadcrumb__bg">
        <div class="container">
          <div class="row row-cols-1">
            <div class="col">
              <div class="breadcrumb__content text-center">
                <h1 class="breadcrumb__content--title text-white mb-25">Checkout</h1>
                <ul class="breadcrumb__content--menu d-flex justify-content-center">
                  <li class="breadcrumb__content--menu__items"><a class="text-white" onClick={() => { navigate("/") }}>Home</a></li>
                  <li class="breadcrumb__content--menu__items"><span class="text-white">Checkout</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End breadcrumb section --> */}

      {/* Start Check Out Page */}
      <section class="checkout__page--area">
        <div class="container-fluid mb-50">
          <h2 class="cart__title mb-40 mt-100" style={{marginTop:"30px",display:"flex",justifyContent:"center"}}>Checkout</h2>
          <div class="checkout__page--inner d-flex">
          <ToastContainer />
          {userId ? (
        <div className="main checkout__main">
          <div className="address-list">
            <h3>Select an Address</h3>
            {addresses.length > 0 ? (
              addresses.map((address) => (
                <div key={address.address_id} className="address-item">
                  <div className="shipping__radio--input">
                    <input
                      type="radio"
                      id={`address-${address.address_id}`}
                      name="address"
                      value={address.address_id}
                      onChange={() => setSelectedAddressId(address.address_id)}
                    />
                  </div>
                  <label
                    className="shipping__radio--label"
                    htmlFor={`address-${address.address_id}`}
                  >
                    <span className="shipping__radio--label__primary">
                      {address.name}, {address.address}, {address.city},{" "}
                      {address.state} - {address.pincode}
                    </span>
                    <span className="shipping__radio--label__secondary">
                      {address.landmark} ({address.label}) - {address.phonenumber}
                    </span>
                  </label>

                  {/* Edit and Delete Icons */}
                  <div className="address__actions">
                    <button onClick={() => handleEditAddress(address)} className="btn btn-sm btn-outline-primary"style={{marginLeft:"255px",marginRight:"5px",fontSize:"1.0rem",backgroundColor:"#ee2761",color:"white", border:"none"}}>
                    <i class="bi bi-pencil"></i> Edit
                    </button>
                    <button onClick={() => handleDeleteAddress(address.address_id)} className="btn btn-sm btn-outline-danger" style={{fontSize:"1.0rem",backgroundColor:"#ee2761",color:"white", border:"none"}}>
                      <i className="bi bi-trash"></i> Delete
                    </button>
                  </div>

                  <hr />
                </div>
              ))
            ) : (
              <p>No addresses found.</p>
            )}
          </div>

          <button
            onClick={() => setIsFormVisible(!isFormVisible)}
            className="btn primary__btn"
          >
            Add Address
          </button>

          {isFormVisible && (
            <form onSubmit={handleFormSubmit}>
              <div className="checkout__content--step section__shipping--address pt-10">
                <div className="section__header mb-25">
                  <h3 className="section__header--title">Billing address</h3>
                  <p className="section__header--desc">
                    Select the address that matches your card or payment method.
                  </p>
                </div>

                {/* Address Form */}
                <div className="checkout__content--step__inner3 border-radius-5">
                    <div className="checkout__content--input__box--wrapper ">
                      <div className="row">
                       
                        <div className="col-lg-6 mb-12">
                          <div className="checkout__input--list ">
                            <label>
                              <input className="checkout__input--field border-radius-5"
                                placeholder="First name "
                                type="text"
                                value={deliveryDetails.delivery_name}
                                onChange={(e) => setDeliveryDetails({ ...deliveryDetails, delivery_name: e.target.value })}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-12">
                          <div className="checkout__input--list">
                            <label>
                              <input className="checkout__input--field border-radius-5"
                                placeholder="Last name (optional)"
                                type="text"
                                value={deliveryDetails.delivery_lastname}
                                onChange={(e) => setDeliveryDetails({ ...deliveryDetails, delivery_lastname: e.target.value })}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mb-12">
                          <div className="checkout__input--list">
                            <label>
                              <input className="checkout__input--field border-radius-5"
                                placeholder="Address1"
                                type="text"
                                value={deliveryDetails.delivery_address}
                                onChange={(e) => setDeliveryDetails({ ...deliveryDetails, delivery_address: e.target.value })}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mb-12">
                          <div className="checkout__input--list">
                            <label>
                              <input className="checkout__input--field border-radius-5"
                                placeholder="City"
                                type="text"
                                value={deliveryDetails.delivery_city}
                                onChange={(e) => setDeliveryDetails({ ...deliveryDetails, delivery_city: e.target.value })}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mb-12">
                          <div className="checkout__input--list">
                            <label>
                              <input className="checkout__input--field border-radius-5"
                                placeholder="State"
                                type="text"
                                value={deliveryDetails.delivery_state}
                                onChange={(e) => setDeliveryDetails({ ...deliveryDetails, delivery_state: e.target.value })}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mb-12">
                          <div className="checkout__input--list">
                            <label>
                              <input className="checkout__input--field border-radius-5"
                                placeholder="Email"
                                type="email"
                                value={deliveryDetails.delivery_email}
                                onChange={(e) => setDeliveryDetails({ ...deliveryDetails, delivery_email: e.target.value })}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="customer__information">
                          <div className="checkout__email--phone mb-12">
                            <label>
                              <input className="checkout__input--field border-radius-5"
                                placeholder="Phone number"
                                type="text"
                                value={deliveryDetails.delivery_phonenumber}
                                onChange={(e) => setDeliveryDetails({ ...deliveryDetails, delivery_phonenumber: e.target.value })}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mb-12">
                          <div className="checkout__input--list">
                            <label>
                              <input className="checkout__input--field border-radius-5"
                                placeholder="Landmark"
                                type="text"
                                value={deliveryDetails.delivery_landmark}
                                onChange={(e) => setDeliveryDetails({ ...deliveryDetails, delivery_landmark: e.target.value })}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mb-12">
                          <div className="checkout__input--list">
                            <label>
                              <input className="checkout__input--field border-radius-5"
                                placeholder="Label (e.g., Home, Office)"
                                type="text"
                                value={deliveryDetails.delivery_label}
                                onChange={(e) => setDeliveryDetails({ ...deliveryDetails, delivery_label: e.target.value })}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-12">
                          <div className="checkout__input--list">
                            <label>
                              <input className="checkout__input--field border-radius-5"
                                placeholder="Postal code"
                                type="text"
                                value={deliveryDetails.delivery_postalcode}
                                onChange={(e) => setDeliveryDetails({ ...deliveryDetails, delivery_postalcode: e.target.value })}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="checkout__address--content__header">
                      <div className="shipping__contact--box__list">
                        <div className="shipping__radio--input">
                          <input className="shipping__radio--input__field" id="radiobox" name="checkmethod" type="radio" />
                        </div>
                        <label className="shipping__radio--label" htmlFor="radiobox">
                          <span className="shipping__radio--label__primary">Cash On Delivery</span>
                        </label>
                      </div>
                      <div className="shipping__contact--box__list">
                        <div className="shipping__radio--input">
                          <input className="shipping__radio--input__field" id="radiobox2" name="checkmethod" type="radio" />
                        </div>
                        <label className="shipping__radio--label" htmlFor="radiobox2">
                          <span className="shipping__radio--label__primary">Online Payment</span>
                        </label>
                      </div>
                    </div>
                  </div>
                

                  <button type="submit" className="btn primary__btn" disabled={loading}>
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          editMode ? "Update Address" : "Submit Address"
        )}
      </button>
              </div>
            </form>
          )}

          <br />
          <br />

          {/* Note Section */}

          <div className="cart__note mb-20">
            <h3 className="cart__note--title">Note</h3>
            <p className="cart__note--desc">
              Add special instructions for your seller...
            </p>
            <textarea
              className="cart__note--textarea border-radius-5"
              value={note}
              onChange={handleNoteChange}
            />
          </div>
          <br /><br />
          <button
      onClick={handleOrderNow}
      className="btn primary__btn"
      disabled={loading}  // Disable button when loading
      
    >
      {loading ? (
        <Spinner animation="border" size="sm" />  // Show spinner when loading
      ) : (
        <p>Order Now</p>  // Show button text when not loading
      )}
    </button>
       
        </div>
      ) : (
        <>
      <p>Please log in to add an address.</p>
      <button onClick={() => navigate('/login')} className="btn primary__btn">
        Log In         
      </button>
    </>
      )}

      {/*------------------------------ Aside product details  --------------------------- */}

            <aside class="checkout__sidebar sidebar">
              <div class="cart__table checkout__product--table">
                <table class="cart__table--inner">
                  <tbody class="cart__table--body">
                    <h5 style={{fontWeight:"bold"}}>Our Products : </h5>
                    {Array.isArray(cartItems)&&cartItems.map((item, index) => (
                      <tr class="cart__table--body__items" key={index}>
                        <td class="cart__table--body__list">
                          <div class="product__image two  d-flex align-items-center">
                            <div class="product__thumbnail border-radius-5">
                              <a onClick={()=>{navigate("/product-details")}}>
                                <img class="border-radius-5"
                                src={item.image}
                                alt="cart-product"
                                loading='lazy'/>
                              </a>
                              <span class="product__thumbnail--quantity">{item.qty}</span>
                            </div>
                            <div class="product__description">
                              <h3 class="product__description--name h4">
                                <a
                                onClick={()=>{navigate('/produt-details')}}>{item.product_name}
                                </a>
                                </h3>
                               
                              {/* <span class="product__description--variant">COLOR: Blue</span> */}
                            </div>
                            
                          </div>
                        </td>
                        <td class="cart__table--body__list">
                          <span class="cart__price"><FaIndianRupeeSign/>{item.tot == 0 ? item.onlinerate : item.tot}</span>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>

             {/*  <div className="col-lg-4">
                    <div className="cart__summary border-radius-10">
                      <div className="coupon__code mb-30">
                        <h3 className="coupon__code--title">Coupon</h3>
                        <p className="coupon__code--desc">Enter your coupon code if you have one.</p>
                        <div className="coupon__code--field d-flex">
                          <label>
                            <input className="coupon__code--field__input border-radius-5" placeholder="Coupon code" type="text" />
                          </label>
                          <button className="coupon__code--field__btn primary__btn" type="submit">Apply Coupon</button>
                        </div>
                      </div>
                      <div className="cart__note mb-20">
                        <h3 className="cart__note--title">Note</h3>
                        <p className="cart__note--desc">Add special instructions for your seller...</p>
                        <textarea className="cart__note--textarea border-radius-5"></textarea>
                      </div>
                      <div className="cart__summary--total mb-20">
                        <table className="cart__summary--total__table">
                          <tbody>
                            <tr className="cart__summary--total__list">
                              <td className="cart__summary--total__title text-left">SUBTOTAL</td>
                              <td className="cart__summary--amount text-right"><i style={{ fontSize: '13px' }} className="fa">&#xf156;</i> {subtotalCurrentPrice}</td>
                            </tr>
                            <tr className="cart__summary--total__list">
                              <td className="cart__summary--total__title text-left">GRAND TOTAL</td>
                              <td className="cart__summary--amount text-right"><i style={{ fontSize: '13px' }} className="fa">&#xf156;</i>{subtotalCurrentPrice} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="cart__summary--footer">
                        <p className="cart__summary--footer__desc">Shipping & taxes calculated at checkout</p>
                        <ul className="d-flex justify-content-between">
                          <li><button className="cart__summary--footer__btn primary__btn cart" type="submit">Update Cart</button></li>
                          <li><a className="cart__summary--footer__btn primary__btn checkout" onClick={() => { navigate("/checkout") }}>Check Out</a></li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
            
              <div class="checkout__total">
              
              <table className="checkout__total--table">
      <tbody className="checkout__total--body">
        <tr className="checkout__total--items">
          <td className="checkout__total--title text-left">Subtotal</td>
          <td className="checkout__total--amount text-right">
            <i style={{ fontSize: '13px' }} className="fa">&#xf156;</i> {subtotalCurrentPrice.toFixed(2)}
          </td>
        </tr>
        <tr className="checkout__total--items">
          <td className="checkout__total--title text-left">Shipping</td>
          <td className="checkout__total--calculated__text text-right">Calculated at next step</td>
        </tr>
        <tr className="checkout__total--items">
          <td className="checkout__total--title text-left">CGST ({cgst}%)</td>
          <td className="checkout__total--amount text-right">
            <i style={{ fontSize: '13px' }} className="fa">&#xf156;</i> {cgstAmount.toFixed(2)}
          </td>
        </tr>
        <tr className="checkout__total--items">
          <td className="checkout__total--title text-left">SGST ({sgst}%)</td>
          <td className="checkout__total--amount text-right">
            <i style={{ fontSize: '13px' }} className="fa">&#xf156;</i> {sgstAmount.toFixed(2)}
          </td>
        </tr>
      </tbody>
      <tfoot className="checkout__total--footer">
        <tr className="checkout__total--footer__items">
          <td className="checkout__total--footer__title checkout__total--footer__list text-left">
            Total
          </td>
          <td className="checkout__total--footer__amount checkout__total--footer__list text-right">
            <i style={{ fontSize: '13px' }} className="fa">&#xf156;</i> {grandTotal.toFixed(2)}
          </td>
        </tr>
      </tfoot>
    </table>
              </div>
            </aside>
            {/*-------------- End aside product details content ------------- */}
          </div>
        </div>
      </section>
      {/* End Checkout page   */}
    </main>
  )
}

export default CheckOut