import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Outlet } from 'react-router-dom'
import OffcanvasMenu from '../components/OffcanvasMenu'
import OffcanvasCart from '../components/OffcanvasCart'


const Layout = ({cartOpen,setCartOpen,menuOpen,setMenuOpen}) => {
  const handleOutsideClick=()=>{
    if(cartOpen){
        setCartOpen(false);
        setMenuOpen(false);
    }
     else if(menuOpen){
        setMenuOpen(false)
        setCartOpen(false)
    }
  
}
  

  return (
   <>
   
    <Header cartOpen={cartOpen} setCartOpen={setCartOpen} menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
    <Outlet context={{handleOutsideClick}}/>
    
    <Footer/>
    {/* off canvas menu */}
    <OffcanvasMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} handleOutsideClick={handleOutsideClick}/>
      {/* off canvas menu end */}

      {/* off canvas cart */}
      <OffcanvasCart cartOpen={cartOpen} setCartOpen={setCartOpen} menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      {/* off canvas cart */}

      
   </>
  )
}

export default Layout