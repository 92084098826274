import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const OffcanvasMenu = ({ menuOpen, setMenuOpen, handleOutsideClick }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [sareeOpen, setSareeOpen] = useState(false);
  const [chuditharOpen, setChuditharOpen] = useState(false);
  const [blouseOpen, setBlouseOpen] = useState(false);

  useEffect(() => {
    // Fetching categories from the API
    fetch('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/subcategory.php')
      .then(response => response.json())
      .then(data => {
        if (data.head.code === 200) {
          setCategories(data.body.category);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleSubcategoryClick = (subcategoryId) => {
    navigate(`/shop?subcategory_id=${subcategoryId}`);
  //   console.log(subcategoryId)
  };
  
  return (
    <div className={`offcanvas__header ${!menuOpen ? "" : "open"}`}>
      <div className="offcanvas__inner">
        <div className="offcanvas__logo">
          <a className="offcanvas__logo_link" href="index.html">
            <img src="assets/img/logo.png" alt="Manjal Silks Logo" width="158" height="36" />
          </a>
          <button className="offcanvas__close--btn" onClick={() => { setMenuOpen(false) }}>close</button>
        </div>
        <nav className="offcanvas__menu">
          <ul className="offcanvas__menu_ul">
            <li className="offcanvas__menu_li">
              <Link className="offcanvas__menu_item" to="/" onClick={handleOutsideClick}>Home</Link>
            </li>
            <li className="offcanvas__menu_li">
              <Link className="offcanvas__menu_item" to="/about" onClick={handleOutsideClick}>About Us</Link>
            </li>
            {categories.map(category => (
              <li key={category.category_id} className="offcanvas__menu_li">
                <a className="offcanvas__menu_item" href="#">{category.category_name}</a>
                <ul className={`offcanvas__sub_menu ${sareeOpen ? "active" : ""}`} style={sareeOpen ? { display: "block" } : { display: 'none' }}>
                  {category.subcategory.map(subcategory => (
                    <li key={subcategory.subcategory_id} className="offcanvas__sub_menu_li">
                      <a className="offcanvas__sub_menu_item" onClick={() => handleSubcategoryClick(subcategory.subcategory_id)}>
                        {subcategory.subcategory_name}
                      </a>
                    </li>
                  ))}
                </ul>
                <button className={`offcanvas__sub_menu_toggle ${!sareeOpen ? "" : "active"}`} onClick={() => setSareeOpen(curr => !curr)}></button>
              </li>
            ))}
            <li className="offcanvas__menu_li">
              <Link className="offcanvas__menu_item" to="/contact" onClick={handleOutsideClick}>Contact Us</Link>
            </li>
            
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default OffcanvasMenu;
