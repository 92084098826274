import './App.css';
import Home from './pages/Home';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './layouts/Layout';
import About from './pages/About';
import Shop from './pages/Shop';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Myaccount from './pages/Myaccount';
import Cart from './pages/Cart';
import { useState } from 'react';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProductDetails from './pages/ProductDetails';
import CheckOut from './pages/CheckOut';
import Terms from './pages/Terms';




function App() {
  const[cartOpen,setCartOpen]=useState(false);
  const[menuOpen,setMenuOpen]=useState(false)
  return (
    <BrowserRouter>
    <Routes>
      <Route   path="/" element={<Layout cartOpen={cartOpen} setCartOpen={setCartOpen} menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>}>

      <Route index element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/shop' element={<Shop/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/account' element={<Myaccount/>}/>
      <Route path='/cart' element={<Cart/>}/>
      <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
      <Route path='/terms' element={<Terms/>}/>
      <Route path='/product-details' element={<ProductDetails/>}/>
      <Route path='/checkout' element={<CheckOut/>}/>
      
      </Route>

      {/* <Route path='*' element={<Navigate to={"/"}/>}/> */}
    </Routes>
    </BrowserRouter>
  );
}

export default App;
