import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const { handleOutsideClick } = useOutletContext();
  const navigate = useNavigate();
  const [forgotPasswordForm, setForgotPasswordForm] = useState({
    email: '',
    otp: '',
  });
  const [step, setStep] = useState('login'); // Tracks the current step ('login', 'forgotPassword', 'otpVerification')
  const [message, setMessage] = useState('');
  
  const [loginForm, setLoginForm] = useState({ email: '', password: '', rememberMe: false });
  const [registerForm, setRegisterForm] = useState({ name: '', email: '', phno: '', password: '', agree: false });

  const handleForgotPasswordClick = () => {
    setStep('forgotPassword');
  };

  const handleLoginChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLoginForm({
      ...loginForm,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleRegisterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRegisterForm({
      ...registerForm,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    
    const loginData = {
      email: loginForm.email,
      password: loginForm.password,
    };
  
    try {
      const response = await fetch('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });
  
      if (!response.ok) {
        throw new Error('Login failed');
      }
  
      const result = await response.json();
      console.log('Login response:', result);
  
      // Check the response structure for success
      if (result.head && result.head.code === 200 && result.head.msg === 'success') {

        // Store the user ID in local storage

        localStorage.setItem('userId', result.body.user_id); // Adjust this line based on the actual response structure

        // console.log(result.body.user_id);

        // Store the userName in local storage

        localStorage.setItem('userName', result.body.name);

        // console.log("========name" + result.body.name);

        navigate('/checkout'); // Redirect to checkout page
      } else {
        alert(result.head.msg || 'Login failed');
      }
  
    } catch (error) {
      console.error('Error during login:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleForgotPasswordChange = (e) => {
    const { name, value } = e.target;
    setForgotPasswordForm({
      ...forgotPasswordForm,
      [name]: value,
    });
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/forgot_request.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: forgotPasswordForm.email
        }),
      });
  
      // Check if the response status is OK (HTTP 200)
      if (response.ok) {
        const data = await response.json(); // Parse the JSON response
  
        if (data.head.code === 200) {
          // Store token and user_id in the state
          setForgotPasswordForm({
            ...forgotPasswordForm,
            token: data.body.token,
            userId: data.body.user_id,
          });
  
          // Move to OTP verification step
          setStep('otpVerification');
        } else {
          // Handle the case where the OTP couldn't be sent
          setMessage('Failed to send OTP');
        }
      } else {
        // If the response is not OK, handle the error
        setMessage('Failed to send OTP');
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while sending the OTP');
    }
  };
  
  
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/forgot_request.php', {
        user_id: forgotPasswordForm.userId,
        reset_code: forgotPasswordForm.otp
      });
  
      if (response.data.head.code === 200 && response.data.body.status === 'code verification success') {
        // OTP is verified, move to reset password step
        setStep('resetPassword');
      } else {
        setMessage('Invalid OTP');
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while verifying the OTP');
    }
  };
  

  const handlePasswordResetSubmit = async (e) => {
  e.preventDefault();

  try {
    const response = await axios.post('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/forgot_request.php', {
      user_id: forgotPasswordForm.userId,
      password: forgotPasswordForm.newPassword
    });

    if (response.data.head.code === 200 && response.data.body.status === 'Password Update Successfully.') {
      setMessage('Password has been updated successfully.');
      // Optionally redirect the user to login page
      setStep('login');
    } else {
      setMessage('Failed to update password');
    }
  } catch (error) {
    console.error(error);
    setMessage('An error occurred while resetting the password');
  }
};

  

const handleRegisterSubmit = async (e) => {
  e.preventDefault();
  
  // Adjusting potential field names based on API expectations
  const registerData = {
    name: registerForm.name, // Try 'name' instead of 'username'
    email: registerForm.email,
    phno: registerForm.phno, // Try 'phonenumber' instead of 'phone'
    password: registerForm.password,
  };

  // Log the data you're sending for debugging
  console.log('Sending registration data:', registerData);

  try {
    const response = await fetch('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/register.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(registerData),
     
    });

    if (!response.ok) {
      throw new Error('Registration failed');
    }

    const result = await response.json();
    console.log('Registration response:', result);

    if (result.success) {
      alert('Registration successful! You can now log in.');
      navigate('/login'); // Redirect to login page
    } else {
      alert(result.head.msg || 'Registration failed');
    }

  } catch (error) {
    console.error('Error during registration:', error);
    alert('An error occurred. Please try again.');
  }
};


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="main__content_wrapper" onClick={handleOutsideClick}>
      <section className="breadcrumb__section breadcrumb__bg">
        <div className="container">
          <div className="row row-cols-1">
            <div className="col">
              <div className="breadcrumb__content text-center">
                <h1 className="breadcrumb__content--title text-white mb-25">Account Page</h1>
                <ul className="breadcrumb__content--menu d-flex justify-content-center">
                  <li className="breadcrumb__content--menu__items"><a className="text-white" onClick={() => { navigate("/") }}>Home</a></li>
                  <li className="breadcrumb__content--menu__items"><span className="text-white">Account Page</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
                 
      <div className="login__section section--padding">
        <div className="container">
          <div className="login__section--inner">
            <div className="row row-cols-md-2 row-cols-1">
            <div className="col">
      <div className="account__login">
        <div className="account__login--header mb-25">
          <h2 className="account__login--header__title h3 mb-10">
            {step === 'login' ? 'Login' : 'Forgot Password'}
          </h2>
          <p className="account__login--header__desc">
            {step === 'login'
              ? 'Login if you are a returning customer.'
              : step === 'forgotPassword'
              ? 'Enter your email to reset your password.'
              : step === 'otpVerification'
              ? 'Enter the OTP sent to your email.'
              : 'Enter your new password to complete the reset.'}
          </p>
        </div>

        <div className="account__login--inner">
          {/* Login Form */}
          {step === 'login' && (
            <form onSubmit={handleLoginSubmit}>
              <input
                className="account__login--input"
                placeholder="Email Address"
                type="text"
                name="email"
                value={loginForm.email}
                onChange={handleLoginChange}
              />
              <input
                className="account__login--input"
                placeholder="Password"
                type="password"
                name="password"
                value={loginForm.password}
                onChange={handleLoginChange}
              />
              <div className="account__login--remember__forgot mb-15 d-flex justify-content-between align-items-center">
                <div className="account__login--remember position__relative">
                  <input
                    className="checkout__checkbox--input"
                    id="check1"
                    type="checkbox"
                    name="rememberMe"
                    checked={loginForm.rememberMe}
                    onChange={handleLoginChange}
                  />
                  <span className="checkout__checkbox--checkmark"></span>
                  <label
                    className="checkout__checkbox--label login__remember--label"
                    htmlFor="check1"
                  >
                    Remember me
                  </label>
                </div>
                <button
                  className="account__login--forgot"
                  type="button"
                  onClick={handleForgotPasswordClick}
                >
                  Forgot Your Password?
                </button>
              </div>
              <button className="account__login--btn primary__btn" type="submit">
                Login
              </button>
            </form>
          )}

          {/* Forgot Password - Email Submission */}
          {step === 'forgotPassword' && (
            <form onSubmit={handleForgotPasswordSubmit}>
              <input
                className="account__login--input"
                placeholder="Enter your Email"
                type="email"
                name="email"
                value={forgotPasswordForm.email}
                onChange={handleForgotPasswordChange}
                
              />
              <button className="account__login--btn primary__btn" type="submit">
                Send OTP
              </button>
            </form>
          )}

          {/* OTP Verification */}
          {step === 'otpVerification' && (
            <form onSubmit={handleOtpSubmit}>
              <input
                className="account__login--input"
                placeholder="Enter 4-digit OTP"
                type="text"
                name="otp"
                value={forgotPasswordForm.otp}
                onChange={handleForgotPasswordChange}
              />
              <button className="account__login--btn primary__btn" type="submit">
                Submit OTP
              </button>
            </form>
          )}

          {/* Reset Password */}
          {step === 'resetPassword' && (
            <form onSubmit={handlePasswordResetSubmit}>
              <input
                className="account__login--input"
                placeholder="Enter New Password"
                type="password"
                name="newPassword"
                value={forgotPasswordForm.newPassword}
                onChange={handleForgotPasswordChange}
              />
              <button className="account__login--btn primary__btn" type="submit">
                Submit New Password
              </button>
            </form>
          )}

          {/* Display any messages like OTP sent or success */}
          {message && <p>{message}</p>}
        </div>
      </div>
    </div>
              <div className="col">
                <div className="account__login register">
                  <div className="account__login--header mb-25">
                    <h2 className="account__login--header__title h3 mb-10">Create an Account</h2>
                    <p className="account__login--header__desc">Register here if you are a new customer.</p>
                  </div>
                  <div className="account__login--inner">
                    <form onSubmit={handleRegisterSubmit}>
                      <input
                        className="account__login--input"
                        placeholder="Username"
                        type="text"
                        name="name"
                        value={registerForm.name}
                        onChange={handleRegisterChange}
                      />
                      <input
                        className="account__login--input"
                        placeholder="Email Address"
                        type="text"
                        name="email"
                        value={registerForm.email}
                        onChange={handleRegisterChange}
                      />
                      <input
                        className="account__login--input"
                        placeholder="Phone Number"
                        type="text"
                        name="phno"
                        value={registerForm.phno}
                        onChange={handleRegisterChange}
                      />
                      <input
                        className="account__login--input"
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={registerForm.password}
                        onChange={handleRegisterChange}
                      />
                      <input
                        className="account__login--input"
                        placeholder="Confirm Password"
                        type="password"
                        name="confirmPassword"
                        value={registerForm.confirmPassword}
                        onChange={handleRegisterChange}
                      />
                      <button className="account__login--btn primary__btn mb-10" type="submit">Submit & Register</button>
                      <div className="account__login--remember position__relative">
                        <input
                          className="checkout__checkbox--input"
                          id="check2"
                          type="checkbox"
                          name="agree"
                          checked={registerForm.agree}
                          onChange={handleRegisterChange}
                        />
                        <span className="checkout__checkbox--checkmark"></span>
                        <label className="checkout__checkbox--label login__remember--label" htmlFor="check2">
                          I have read and agree to the terms & conditions
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
