import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';
import axios from 'axios';
import { useNavigate, useOutletContext } from 'react-router-dom'
import Loader from '../components/Loader';



const About = () => {
  const navigate=useNavigate();
  const{handleOutsideClick}=useOutletContext();
  const [aboutContent, setAboutContent] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state

  // Fetch the about content from the API
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when entering the page

    // Fetch content from the API
    axios.post('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/content.php')
      .then(response => {
        // Ensure the response is valid and contains the 'home' content
        if (response.data?.body?.about?.value) {
          setAboutContent(response.data.body.about.value);
          console.log("about content " + response.data.body.about.value);
        } else {
          console.error("No content available");
        }
      })
      .catch(error => {
        console.error("Error fetching about content:", error);
      })
      .finally(() => {
        setLoading(false); // Turn off the loader regardless of success or error
      });
  }, []);


  return (
    <main className="main__content_wrapper" onClick={handleOutsideClick}>

      {/* Start breadcrumb section */}
      {loading ? (
        <Loader />
      ) : (
        <>

      <section className="breadcrumb__section breadcrumb__bg">
        <div className="container">
          <div className="row row-cols-1">
            <div className="col">
              <div className="breadcrumb__content text-center">
                <h1 className="breadcrumb__content--title text-white mb-25">About Us</h1>
                <ul className="breadcrumb__content--menu d-flex justify-content-center">
                  <li className="breadcrumb__content--menu__items">
                    <a className="text-white" onClick={() => { navigate("/") }}>Home</a>
                  </li>
                  <li className="breadcrumb__content--menu__items">
                    <span className="text-white">About Us</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End breadcrumb section */}

      {/* Start about section */}
      <section className="about__section section--padding mb-95">
        <div className="container">
          <div className="row">
           {/*  <div className="col-lg-4">
              <div className="about__thumb d-flex">
                <div className="about__thumb--items position__relative">
                  <img className="about__thumb--img border-radius-5 display-block" src="assets/img/other/about.jpg" alt="about-thumb" />
                  <div className="banner__bideo--play about__thumb--play">
                    <a className="banner__bideo--play__icon about__thumb--play__icon glightbox" href="https://vimeo.com/115041822" data-gallery="video">
                      SVG for play icon
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-10">
              <div className="about__content">
                <span className="about__content--subtitle text__secondary mb-20">Why Choose us</span>
                <h2 className="about__content--maintitle mb-25">We do not buy from the open market & traders.</h2>
                {/* Dynamically inject the about content fetched from API */}
                
                <div
                  className="about__content--desc mb-20"
                  dangerouslySetInnerHTML={{ __html: aboutContent }} // Set HTML content safely
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End about section */}

      {/* Start counterup banner section */}
      <div className="counterup__banner--section counterup__banner__bg2 mb-95" id="funfactId">
        <div className="container">
          <div className="row row-cols-1 align-items-center">
            <div className="col">
              <div className="counterup__banner--inner position__relative d-flex align-items-center justify-content-between">
                <div className="counterup__banner--items text-center">
                  <h2 className="counterup__banner--items__text text-white">YEARS OF <br /> FOUNDATION</h2>
                  <span className="counterup__banner--items__number js-counter text-white" data-count="15"><CountUp end={15} duration={5} /></span>
                </div>
                <div className="counterup__banner--items text-center">
                  <h2 className="counterup__banner--items__text text-white">SKILLED TEAM <br /> MEMBERS</h2>
                  <span className="counterup__banner--items__number js-counter text-white" data-count="25"><CountUp end={25} duration={5} /></span>
                </div>
                <div className="counterup__banner--items text-center">
                  <h2 className="counterup__banner--items__text text-white">HAPPY <br /> CUSTOMERS</h2>
                  <span className="counterup__banner--items__number js-counter text-white" data-count="1300"><CountUp end={1300} duration={5} /></span>
                </div>
                <div className="counterup__banner--items text-center">
                  <h2 className="counterup__banner--items__text text-white">MONTHLY <br /> ORDERS</h2>
                  <span className="counterup__banner--items__number js-counter text-white" data-count="100"><CountUp end={100} duration={5} /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End counterup banner section */}
      </>
      )}
    </main>
  )
}

export default About