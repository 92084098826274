import React, { useEffect, useState,useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import 'swiper/swiper-bundle.css';
import { Navigation } from 'swiper/modules';
import { FaRupeeSign } from 'react-icons/fa';
import '../components/ProductDetails.css'
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { CartContext } from '../context/CartContext';
import axios from 'axios';




const ProductDetails = () => {
  const { cartItems, addItemToCart, removeItemFromCart } = useContext(CartContext);
  const [selectedImage, setSelectedImage] = useState(''); // Set the default image as the first one
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const location = useLocation();
  const [cart, setCart] = useState([]);
  const [message, setMessage] = useState('');
  const [cartMessageProductId, setCartMessageProductId] = useState(null); 
  //const queryParams = new URLSearchParams(location.search);
  //const productId = queryParams.get('id');
  const [addedToCart, setAddedToCart] = useState(false); // State to track if the product is in the cart
  const searchParams = new URLSearchParams(location.search);

  const productId = searchParams.get('product_id'); // Get product_id
    const subcategory_id = searchParams.get('subcategory_id');

  const navigate=useNavigate();

  const [tab, setTab] = useState("tab1");
  
  useEffect(() => {
    GLightbox({ selector: '.glightbox' });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  // Function to handle thumbnail click and update the selected image
  const handleThumbnailClick = (imgSrc) => {
    setSelectedImage(imgSrc); // Update the selected image
  };

  const handleColorClick = (image) => {
    setSelectedImage(image);
  };

   

  const handleIncrease = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + 1;
      
      // Update the cart with the new quantity
      const updatedProduct = {
        product_id: productDetails.product_id, // Same product
        product_name: productDetails.product_name,
        image: selectedImage,
        qty: newQuantity,
        onlinerate: productDetails.onlinerate,
        tot: productDetails.onlinerate * newQuantity
      };
      
      addItemToCart(updatedProduct); // Add to cart context with updated qty
      
      return newQuantity;
    });
  };
  
  const handleDecrease = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity > 1) {
        const newQuantity = prevQuantity - 1;
        
        // Update the cart with the new quantity
        const updatedProduct = {
          product_id: productDetails.product_id, // Same product
          product_name: productDetails.product_name,
          image: selectedImage,
          qty: newQuantity,
          onlinerate: productDetails.onlinerate,
          mrprate:productDetails.mrprate,
          tot: productDetails.onlinerate * newQuantity
        };
        
        addItemToCart(updatedProduct); // Add to cart context with updated qty
        
        return newQuantity;
      }
      return prevQuantity; // Do not go below 1
    });
  };
  

  const handleQuantityChange = (e) => {
    const value = Math.max(1, e.target.value); // Ensure quantity is at least 1
    setQuantity(value);
  };


  useEffect(() => {
    console.log("Subcategory ID:", subcategory_id); 
    const fetchProductDetails = async () => {
      try {
        const response = await fetch("https://www.vedanthfashions.com/vfadmin/vfcustomerapi/product_details.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ product_id: productId })
        });

        const data = await response.json();
        console.log("Product Details: ", data.body.product_detail[0]); // Log to verify
        setProductDetails(data.body.product_detail[0]);

        // Set the initial selected image if images exist
        if (data.body.product_detail[0]) {
          const images = [
            data.body.product_detail[0].image,
            data.body.product_detail[0].image2,
            data.body.product_detail[0].image3,
            data.body.product_detail[0].image4,
          ];
          setSelectedImage(images[0]); // Set the first image as selected
        }

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId, subcategory_id]);


  useEffect(() => {
    console.log('Subcategory ID:', subcategory_id);

    const fetchRelatedProducts = async () => {
        if (!subcategory_id) return; // If subcategory_id is null, don't fetch data

        try {
            const response = await fetch('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/product.php', {
                method: 'POST', // Change to POST method
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sc_id: subcategory_id }), // Send subcategory_id in the request body
            });

            const data = await response.json();
            console.log('Fetched products:', JSON.stringify(data, null, 2)); // Log the fetched data

            if (data && data.head) {
                if (data.head.code === 200) {
                    if (data.body && Array.isArray(data.body.products)) {
                        setRelatedProducts(data.body.products);
                        console.log('Related Products:', data.body.products);
                    } else {
                        console.error('Invalid products structure:', data.body.products);
                    }
                } else {
                    console.error('Error in API response:', data.head.msg);
                    setError(`Error: ${data.head.msg} (Code: ${data.head.code})`); // Update error state
                }
            } else {
                console.error('No products found or invalid response structure');
            }
        } catch (error) {
            console.error('Error fetching related products:', error);
            setError('An error occurred while fetching related products.'); // Update error state
        }
    };

    fetchRelatedProducts();
}, [subcategory_id]); // Depend on subcategory_id


  
  



  // Prepare slides array from product details if available
  const slides = productDetails
    ? [
        productDetails.image,
        productDetails.image2,
        productDetails.image3,
        productDetails.image4,
      ].filter(Boolean) // Filter to remove any undefined images
    : [];

  const handleAddToCart = () => {
  // Ensure that the correct product data is added to the cart
  const productWithQuantity = {
    product_id: productDetails.product_id,    // Product ID
    product_name: productDetails.product_name, // Product name
    image: selectedImage,                     // Selected image or color
    qty: quantity,                            // Quantity selected
    onlinerate: productDetails.onlinerate,    // Online rate or price
    mrprate: productDetails.mrprate,          // MRP rate
    tot: productDetails.onlinerate * quantity, // Total price (quantity * price)
    main_category_id: productDetails.main_category_id || 'N/A', // Ensure main category ID is included
    sub_category_id: productDetails.sub_category_id || 'N/A'      // Ensure sub category ID is included
  };

  // Check if the product with the selected image already exists in the cart
  const existingCartItem = cartItems.find(item => 
    item.product_id === productDetails.product_id && item.image === selectedImage
  );

  if (existingCartItem) {
    // If the item with the same product_id and image exists, update its quantity
    const updatedCartItems = cartItems.map(item => 
      item.product_id === productDetails.product_id && item.image === selectedImage
        ? { 
            ...item, 
            qty: item.qty + quantity, 
            tot: item.tot + productDetails.onlinerate * (item.qty + quantity), // Update total price
            main_category_id: item.main_category_id || 'N/A', // Maintain category IDs
            sub_category_id: item.sub_category_id || 'N/A'
          }
        : item
    );
    setCart(updatedCartItems); // Update the cart context or state
  } else {
    // If the item doesn't exist, add it as a new item
    addItemToCart(productWithQuantity);
    setMessage('Item added to cart!');

        // Optionally, clear the message after a few seconds
        setTimeout(() => {
            setMessage('');
        }, 2000);
  }

  setAddedToCart(true); // Show "Show Cart" button or any other UI change
};


    
    const handleShowCart = () => {
      navigate('/cart'); // Navigate to the cart page
    };
  
    const handleAddedToCart = (product) => {
      const productWithQuantity = {
        product_id: product.product_id,
        product_name: product.product_name,
        image: product.image,
        qty: 1,
        onlinerate: product.onlinerate,
        mrprate: product.mrprate,
        tot: product.onlinerate,
        main_category_id: product.main_category_id || 'N/A',
        sub_category_id: product.sub_category_id || 'N/A'
      };
    
      // Check if the product already exists in the cart
      const existingCartItem = cartItems.find(item => 
        item.product_id === product.product_id && item.image === product.image
      );
    
      if (existingCartItem) {
        // If the item with the same product_id and image exists, update its quantity
        const updatedCartItems = cartItems.map(item => 
          item.product_id === product.product_id && item.image === product.image
            ? { 
                ...item, 
                qty: item.qty + 1, 
                tot: item.onlinerate * (item.qty + 1),
              }
            : item
        );
        setCart(updatedCartItems);
      } else {
        // If the item doesn't exist, add it as a new item
        addItemToCart(productWithQuantity);
      }
    
      // Set the message for the specific product
      setCartMessageProductId(product.product_id);
      setMessage('Item added to cart!');
    
      // Clear the message after a few seconds
      setTimeout(() => {
        setMessage('');
        setCartMessageProductId(null);  // Clear the specific product ID message
      }, 2000);
    
      setAddedToCart(true);
    };    


  return (
    <main class="main__content_wrapper">

      {/* <!-- Start breadcrumb section --> */}
      <section className="breadcrumb__section breadcrumb__bg">
        <div className="container">
          <div className="row row-cols-1">
            <div className="col">
              <div className="breadcrumb__content text-center">
                <h1 className="breadcrumb__content--title text-white mb-25">Product Details</h1>
                <ul className="breadcrumb__content--menu d-flex justify-content-center">
                  <li className="breadcrumb__content--menu__items">
                    <a className="text-white" onClick={() => { navigate("/") }}>Home</a>
                  </li>
                  <li className="breadcrumb__content--menu__items">
                    <span className="text-white">Product Details</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {productDetails ? (
        <section className="product__details--section section--padding">
          <div className="container">
            <div className="row row-cols-lg-2 row-cols-md-2">
              <div className="col">
                <div className="product__details--media">
                  <div className="product__media--preview swiper">
                    <Swiper spaceBetween={10} slidesPerView={1}>
                      <SwiperSlide>
                        <div className="product__media--preview__items">
                          <a
                            className="product__media--preview__items--link glightbox"
                            data-gallery="product-media-preview"
                            href={selectedImage}
                          >
                            <img
                              className="product__media--preview__items--img"
                              src={selectedImage}
                              alt="product-media-img"
                              loading='lazy'
                            />
                          </a>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>

                  <div className="product__media--nav swiper">
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={5}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                      modules={[Navigation]}
                    >
                      {['image', 'image2', 'image3', 'image4'].map((imgKey, idx) => (
                        productDetails[imgKey] && (
                          <SwiperSlide key={idx}>
                            <div
                              className="product__media--nav__items"
                              onClick={() => handleThumbnailClick(productDetails[imgKey])}
                            >
                              <img
                                className="product__media--nav__items--img"
                                src={productDetails[imgKey]}
                                alt={`product-nav-img-${idx}`}
                                loading='lazy'
                              />
                            </div>
                          </SwiperSlide>
                        )
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="product__details--info">
                  <h2 className="product__details--info__title mb-15">
                    {productDetails.product_name}
                  </h2>
                  <div className="product__details--info__price mb-10">
                    <span className="current__price">
                      <i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {productDetails.onlinerate}
                    </span>
                    <span className="price__divided"></span>
                    <span className="old__price">
                      <i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {productDetails.mrprate}
                    </span>
                  </div>

                  <p className="product__details--info__desc mb-15">
                    {productDetails.description}
                  </p>

                  <div className="product__variant">
                    <fieldset className="variant__input--fieldset">
                      <legend className="product__variant--title mb-8">Color:</legend>
                      {['image', 'image2', 'image3', 'image4'].map((imgKey, idx) => (
                        productDetails[imgKey] && (
                          <React.Fragment key={idx}>
                            <input
                              id={`color-${idx}`}
                              name="color"
                              type="radio"
                              defaultChecked={idx === 0}
                              onChange={() => handleThumbnailClick(productDetails[imgKey])}
                            />
                            <label
                              className="variant__color--value red"
                              htmlFor={`color-${idx}`}
                              title={`Image ${idx + 1}`}
                            >
                              <img
                                className="variant__color--value__img"
                                src={productDetails[imgKey]}
                                alt={`variant-color-img-${idx + 1}`}
                              />
                            </label>
                          </React.Fragment>
                        )
                      ))}
                    </fieldset>
                    <br />
                    <div className="quantity d-flex align-items-center mb-20">
                      <div className="quantity__box">
                        <button
                          type="button"
                          className="quantity__value decrease"
                          onClick={handleDecrease}
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="quantity__number"
                          value={quantity}
                          onChange={handleQuantityChange}
                          min="1"
                        />
                        <button
                          type="button"
                          className="quantity__value increase"
                          onClick={handleIncrease}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    {message && <div className="message">{message}</div>}
                <button
                  className="quickview__cart--btn primary__btn"
                  type="button"
                  onClick={handleAddToCart}
                >
                  Add To Cart
                </button>
                 <br /> <br />
                    <div>
                      <p>Category: {productDetails.main_category_name}</p>
                    </div>
                    <br />
                    <div>
                      <p>Type: {productDetails.sub_category_name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div>Product details not available</div>
      )}

      {/* End Product Details Section */}

      {/* <!-- Start product details tab section --> */}
      <section class="product__details--tab__section section--padding">
        <div class="container">
          <div class="row row-cols-1">
            <div class="col">
              <ul class="product__details--tab d-flex mb-30">
                <li className={`product__details--tab__list ${tab === "tab1" ? "active" : ""}`} data-toggle="tab" data-target="#description" onClick={() => setTab("tab1")}>Description</li>
                {/* <li className={`product__details--tab__list ${tab === "tab2" ? "active" : ""}`} data-toggle="tab" data-target="#reviews" onClick={() => setTab("tab2")}>Product Reviews</li>
                <li className={`product__details--tab__list ${tab === "tab3" ? "active" : ""}`} data-toggle="tab" data-target="#information" onClick={() => setTab("tab3")}>Additional Info</li>
                <li className={`product__details--tab__list ${tab === "tab4" ? "active" : ""}`} data-toggle="tab" data-target="#custom" onClick={() => setTab("tab4")}>Custom Content</li> */}
              </ul>
              <div class="product__details--tab__inner border-radius-10">
                <div class="tab_content">
                  <div id="description" class={`tab_pane ${tab === "tab1" ? "active show" : ""}`}>
                    <div class="product__tab--content">
                      <div class="product__tab--content__step mb-30">
                        <h2 class="product__tab--content__title h4 mb-10">Product Description :</h2>
                        {productDetails ? (
                             <p className="product__details--info__desc mb-15">
                               {productDetails.description}
                             </p>
                             ) : (
                            <p>Loading...</p> // You can show a loading message or a placeholder
                           )}                      
                       </div>
                     {/*  <div class="product__tab--content__step">
                        <h4 class="product__tab--content__title mb-10">More Details</h4>
                        <ul>
                          <li class="product__tab--content__list">
                            <svg class="product__tab--content__list--icon" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, dolorum?
                          </li>
                          <li class="product__tab--content__list">
                            <svg class="product__tab--content__list--icon" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg>
                            Magnam enim modi, illo harum suscipit tempore aut dolore?
                          </li>
                          <li class="product__tab--content__list">
                            <svg class="product__tab--content__list--icon" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg>
                            Numquam eaque mollitia fugiat laborum dolor tempora;
                          </li>
                          <li class="product__tab--content__list">
                            <svg class="product__tab--content__list--icon" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg>
                            Sit amet consectetur adipisicing elit.  Quo delectus repellat facere maiores.
                          </li>
                          <li class="product__tab--content__list">
                            <svg class="product__tab--content__list--icon" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg>
                            Repellendus itaque sit quia consequuntur, unde veritatis. dolorum?
                          </li>
                        </ul> 
                      </div> */}
                    </div>
                  </div>
                  {/* <div id="reviews" class={`tab_pane ${tab === "tab2" ? "active show" : ""}`}>
                    <div class="product__reviews">
                      <div class="product__reviews--header">
                        <h2 class="product__reviews--header__title h3 mb-20">Customer Reviews</h2>
                        <div class="reviews__ratting d-flex align-items-center">
                          <ul class="rating d-flex">
                            <li class="rating__list">
                              <span class="rating__list--icon">
                                <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                  <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                </svg>
                              </span>
                            </li>
                            <li class="rating__list">
                              <span class="rating__list--icon">
                                <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                  <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                </svg>
                              </span>
                            </li>
                            <li class="rating__list">
                              <span class="rating__list--icon">
                                <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                  <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                </svg>
                              </span>
                            </li>
                            <li class="rating__list">
                              <span class="rating__list--icon">
                                <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                  <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                </svg>
                              </span>
                            </li>
                            <li class="rating__list">
                              <span class="rating__list--icon">
                                <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                  <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                </svg>
                              </span>
                            </li>
                          </ul>
                          <span class="reviews__summary--caption">Based on 2 reviews</span>
                        </div>
                        <a class="actions__newreviews--btn primary__btn" href="#writereview">Write A Review</a>
                      </div>
                      <div class="reviews__comment--area">
                        <div class="reviews__comment--list d-flex">
                          <div class="reviews__comment--thumb">
                            <img src="assets/img/other/comment-thumb1.png" alt="comment-thumb" />
                          </div>
                          <div class="reviews__comment--content">
                            <div class="reviews__comment--top d-flex justify-content-between">
                              <div class="reviews__comment--top__left">
                                <h3 class="reviews__comment--content__title h4">Richard Smith</h3>
                                <ul class="rating reviews__comment--rating d-flex">
                                  <li class="rating__list">
                                    <span class="rating__list--icon">
                                      <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                        <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                      </svg>
                                    </span>
                                  </li>
                                  <li class="rating__list">
                                    <span class="rating__list--icon">
                                      <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                        <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                      </svg>
                                    </span>
                                  </li>
                                  <li class="rating__list">
                                    <span class="rating__list--icon">
                                      <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                        <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                      </svg>
                                    </span>
                                  </li>
                                  <li class="rating__list">
                                    <span class="rating__list--icon">
                                      <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                        <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                      </svg>
                                    </span>
                                  </li>
                                  <li class="rating__list">
                                    <span class="rating__list--icon">
                                      <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                        <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                      </svg>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <span class="reviews__comment--content__date">February 18, 2022</span>
                            </div>
                            <p class="reviews__comment--content__desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eos ex repellat officiis neque. Veniam, rem nesciunt. Assumenda distinctio, autem error repellat eveniet ratione dolor facilis accusantium amet pariatur, non eius!</p>
                          </div>
                        </div>
                        <div class="reviews__comment--list margin__left d-flex">
                          <div class="reviews__comment--thumb">
                            <img src="assets/img/other/comment-thumb2.png" alt="comment-thumb" />
                          </div>
                          <div class="reviews__comment--content">
                            <div class="reviews__comment--top d-flex justify-content-between">
                              <div class="reviews__comment--top__left">
                                <h3 class="reviews__comment--content__title h4">Laura Johnson</h3>

                              </div>
                              <span class="reviews__comment--content__date">February 18, 2022</span>
                            </div>
                            <p class="reviews__comment--content__desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eos ex repellat officiis neque. Veniam, rem nesciunt. Assumenda distinctio, autem error repellat eveniet ratione dolor facilis accusantium amet pariatur, non eius!</p>
                          </div>
                        </div>
                        <div class="reviews__comment--list d-flex">
                          <div class="reviews__comment--thumb">
                            <img src="assets/img/other/comment-thumb3.png" alt="comment-thumb" />
                          </div>
                          <div class="reviews__comment--content">
                            <div class="reviews__comment--top d-flex justify-content-between">
                              <div class="reviews__comment--top__left">
                                <h3 class="reviews__comment--content__title h4">John Deo</h3>
                                <ul class="rating reviews__comment--rating d-flex">
                                  <li class="rating__list">
                                    <span class="rating__list--icon">
                                      <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                        <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                      </svg>
                                    </span>
                                  </li>
                                  <li class="rating__list">
                                    <span class="rating__list--icon">
                                      <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                        <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                      </svg>
                                    </span>
                                  </li>
                                  <li class="rating__list">
                                    <span class="rating__list--icon">
                                      <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                        <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                      </svg>
                                    </span>
                                  </li>
                                  <li class="rating__list">
                                    <span class="rating__list--icon">
                                      <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                        <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                      </svg>
                                    </span>
                                  </li>
                                  <li class="rating__list">
                                    <span class="rating__list--icon">
                                      <svg class="rating__list--icon__svg" xmlns="http://www.w3.org/2000/svg" width="14.105" height="14.732" viewBox="0 0 10.105 9.732">
                                        <path data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"></path>
                                      </svg>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <span class="reviews__comment--content__date">February 18, 2022</span>
                            </div>
                            <p class="reviews__comment--content__desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eos ex repellat officiis neque. Veniam, rem nesciunt. Assumenda distinctio, autem error repellat eveniet ratione dolor facilis accusantium amet pariatur, non eius!</p>
                          </div>
                        </div>
                      </div>
                      <div id="writereview" class="reviews__comment--reply__area">
                        <form action="#">
                          <h3 class="reviews__comment--reply__title mb-15">Add a review </h3>
                          <div class="reviews__ratting d-flex align-items-center mb-20">

                          </div>
                          <div class="row">
                            <div class="col-12 mb-10">
                              <textarea class="reviews__comment--reply__textarea" placeholder="Your Comments...." ></textarea>
                            </div>
                            <div class="col-lg-6 col-md-6 mb-15">
                              <label>
                                <input class="reviews__comment--reply__input" placeholder="Your Name...." type="text" />
                              </label>
                            </div>
                            <div class="col-lg-6 col-md-6 mb-15">
                              <label>
                                <input class="reviews__comment--reply__input" placeholder="Your Email...." type="email" />
                              </label>
                            </div>
                          </div>
                          <button class="reviews__comment--btn text-white primary__btn" data-hover="Submit" type="submit">SUBMIT</button>
                        </form>
                      </div>
                    </div>
                  </div> */}
                  {/* <div id="information" class={`tab_pane ${tab === "tab3" ? "active show" : ""}`}>
                    <div class="product__tab--conten">
                      <div class="product__tab--content__step mb-30">
                        <h2 class="product__tab--content__title h4 mb-10">Nam provident sequi</h2>
                        <p class="product__tab--content__desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam provident sequi, nemo sapiente culpa nostrum rem eum perferendis quibusdam, magnam a vitae corporis! Magnam enim modi, illo harum suscipit tempore aut dolore doloribus deserunt voluptatum illum, est porro? Ducimus dolore accusamus impedit ipsum maiores, ea iusto temporibus numquam eaque mollitia fugiat laborum dolor tempora eligendi voluptatem quis necessitatibus nam ab?</p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div id="custom" class={`tab_pane ${tab === "tab1" ? "active show" : ""}`}>
                    <div class="product__tab--content">
                      <div class="product__tab--content__step mb-30">
                        <h2 class="product__tab--content__title h4 mb-10">Nam provident sequi</h2>
                        <p class="product__tab--content__desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam provident sequi, nemo sapiente culpa nostrum rem eum perferendis quibusdam, magnam a vitae corporis! Magnam enim modi, illo harum suscipit tempore aut dolore doloribus deserunt voluptatum illum, est porro? Ducimus dolore accusamus impedit ipsum maiores, ea iusto temporibus numquam eaque mollitia fugiat laborum dolor tempora eligendi voluptatem quis necessitatibus nam ab?</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End product details tab section --> */}

      {/* Start Product Section */}
      <section className="product__section product__section--style3 section--padding">
      <div className="container product3__section--container ">
        <div className="section__heading text-center mb-50">
          <h2 className="section__heading--maintitle">You may also like</h2>
        </div>
        <div className="product__section--inner product__swiper--column4__activation swiper">
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
         }}
         modules={[Navigation]}
         breakpoints={{
          // Define breakpoints for responsiveness
          320: {
            slidesPerView: 1, // 1 slide for mobile (320px wide)
            spaceBetween: 10, // Adjust spacing for small screens
          },
          425: {
            slidesPerView: 2, // 2 slides for larger mobile screens (425px wide)
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 3, // 3 slides for tablets (768px wide)
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4, // 4 slides for desktop screens
            spaceBetween: 30,
          },
        }}
         className="swiper-wrapper"
        >
  {relatedProducts.length > 0 ? (
    relatedProducts.map((product) => (
      <SwiperSlide key={product.product_id}> {/* Use the  product ID (or) unique key */}
        <div className="product__items">
          <div className="product__items--thumbnail">
            <a className="product__items--link">
              <img
                className="product__items--img product__primary--img"
                src={product.image} // Use the actual image property from your API
                alt={product.product_name}
                loading='lazy'
              />
            </a>
            <div className="product__badge">
              <span className="product__badge--items sale">Sale</span>
            </div>
          </div>
          <div className="product__items--content">
            <h3 className="product__items--content__title h4">
              <a onClick={() => { navigate(`/product-details?product_id=${product.product_id}&subcategory_id=${subcategory_id}`); }}>
                {product.product_name} 
              </a>
            </h3>
            <div className="product__items--price">
              <span className="current__price">
                <FaRupeeSign style={{ fontSize: '13px' }} />{product.onlinerate}
              </span>
              <span className="price__divided"></span>
              <span className="old__price">
                <FaRupeeSign style={{ fontSize: '13px' }} /> {product.mrprate} 
              </span>
            </div>
            {cartMessageProductId === product.product_id && message && (
        <span className="cart-message">{message}</span>
      )}
            <ul className="product__items--action d-flex">
            {cartItems.find(cartItem => cartItem.product_id === product.product_id) === undefined ? (
              <li className="product__items--action__list">
                {/* Add to Cart button */}
                <button 
                  className="product__items--action__btn add__to--cart"
                  onClick={() => handleAddedToCart(product)} // Call the Add to Cart function
                  style={{marginTop:"15px"}}
                >
                  <span className="add__to--cart__text1"> + Add to cart</span>
                </button>
              </li>
              ) : (
                <li className="product__items--action__list">
                                        <a className="product__items--action__btn add__to--cart">
                                            <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534" onClick={() => navigate("/cart")}>
                                                <g transform="translate(0 0)">
                                                    <g>
                                                        <path data-name="Path 16787" d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z" transform="translate(0 -463.248)" fill="currentColor"></path>
                                                        <path data-name="Path 16788" d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z" transform="translate(-1.191 -466.622)" fill="currentColor"></path>
                                                        <path data-name="Path 16789" d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z" transform="translate(-2.875 -466.622)" fill="currentColor"></path>
                                                    </g>
                                                </g>. 
                                            </svg>
                                            <span className="add__to--cart__text" onClick={() => navigate("/cart")}>
                                                Show Cart
                                            </span>
                                        </a>
                                    </li>
                                )}
                                </ul>
                              </div>
                            </div>
                            </SwiperSlide>
                          ))
                      ) : (
                       <div>No related products found.</div> 
                      )}
                     </Swiper>

          <div className="swiper__nav--btn swiper-button-next"></div>
          <div className="swiper__nav--btn swiper-button-prev"></div>
        </div>
      </div>
    </section>
      {/* End Product Section */}

      {/* <!-- Start shipping section --> */}
        {/* <section class="shipping__section2 shipping__style3 section--padding pt-0">
            <div class="container">
                <div class="shipping__section2--inner shipping__style3--inner d-flex justify-content-between">
                    <div class="shipping__items2 d-flex align-items-center">
                        <div class="shipping__items2--icon">
                            <img src="assets/img/other/shipping1.png" alt=""/>
                        </div>
                        <div class="shipping__items2--content">
                            <h2 class="shipping__items2--content__title h3">Shipping</h2>
                            <p class="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div class="shipping__items2 d-flex align-items-center">
                        <div class="shipping__items2--icon">
                            <img src="assets/img/other/shipping2.png" alt=""/>
                        </div>
                        <div class="shipping__items2--content">
                            <h2 class="shipping__items2--content__title h3">Payment</h2>
                            <p class="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div class="shipping__items2 d-flex align-items-center">
                        <div class="shipping__items2--icon">
                            <img src="assets/img/other/shipping3.png" alt=""/>
                        </div>
                        <div class="shipping__items2--content">
                            <h2 class="shipping__items2--content__title h3">Return</h2>
                            <p class="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div class="shipping__items2 d-flex align-items-center">
                        <div class="shipping__items2--icon">
                            <img src="assets/img/other/shipping4.png" alt=""/>
                        </div>
                        <div class="shipping__items2--content">
                            <h2 class="shipping__items2--content__title h3">Support</h2>
                            <p class="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!-- End shipping section --> */}
    </main>
  )
}

export default ProductDetails