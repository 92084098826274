import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { FaIndianRupeeSign } from "react-icons/fa6";
import Loader from "../components/Loader";

const Myaccount = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const { handleOutsideClick } = useOutletContext();
  const [orders, setOrders] = useState([]);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  
  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchOrderDetails = async () => {
      try {
        const response = await axios.post(
          "https://www.vedanthfashions.com/vfadmin/vfcustomerapi/orderdetail.php",
          {
            user_id: userId,
          }
        );

        console.log(response.data); // Log the response to inspect the structure

        if (response.data.head.code === 200) {
          setOrders(response.data.body.order_detail);

          // Access the first element of user_detail array and set the userName
          if (
            response.data.body.user_detail &&
            response.data.body.user_detail.length > 0
          ) {
            setUserName(response.data.body.user_detail[0].user_name);
          } else {
            console.error("User details are not available in the response");
          }
        } else {
          console.error("Failed to fetch order details");
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchOrderDetails();
  }, [userId]);

  return (
    <main className="main__content_wrapper" onClick={handleOutsideClick}>
      {loading ? (
        <Loader />
      ) : (
        <>
      {/* <!-- Start breadcrumb section --> */}
      <section className="breadcrumb__section breadcrumb__bg">
        <div className="container">
          <div className="row row-cols-1">
            <div className="col">
              <div className="breadcrumb__content text-center">
                <h1 className="breadcrumb__content--title text-white mb-25">
                  My Account
                </h1>

                <ul className="breadcrumb__content--menu d-flex justify-content-center">
                  <li className="breadcrumb__content--menu__items">
                    <a
                      className="text-white"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb__content--menu__items">
                    <span className="text-white">My Account</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End breadcrumb section --> */}

      <section className="my__account--section section--padding">
        <div className="container">
          <p className="account__welcome--text">
            Hello,{userName} welcome to your Account !
          </p>
          <div className="my__account--section__inner border-radius-10 d-flex">
            <div className="account__left--sidebar">
              <h2 className="account__content--title h3 mb-20"></h2>
              <ul className="account__menu">
                <li className="account__menu--list active">
                  <a
                    onClick={() => {
                      navigate("/account");
                    }}
                  >
                    {userName.toUpperCase()}
                  </a>
                </li>
                <li className="account__menu--list">
                  <a
                    onClick={() => {
                      localStorage.removeItem("userId");
                      navigate("/login");
                    }}
                  >
                    Log Out
                  </a>
                </li>
              </ul>
            </div>
            <div className="account__wrapper">
              <div className="account__content">
                <h2 className="account__content--title h3 mb-20">
                  Orders History
                </h2>
                {userId ? (
                  <div className="account__table--area">
                    <table className="account__table">
                      <thead className="account__table--header">
                        <tr className="account__table--header__child">
                          <th className="account__table--header__child--items">
                            Order ID
                          </th>
                          <th className="account__table--header__child--items">
                            Date
                          </th>
                          <th className="account__table--header__child--items">
                            Image
                          </th>
                          <th className="account__table--header__child--items">
                            Product Name
                          </th>
                          <th className="account__table--header__child--items">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody className="account__table--body mobile__none">
                        {orders.map((order) => (
                          <tr
                            className="account__table--body__child"
                            key={order.order_id}
                          >
                            <td className="account__table--body__child--items">
                              {order.order_id}
                            </td>
                            <td className="account__table--body__child--items">
                              {order.order_date}
                            </td>
                            <td className="account__table--body__child--items">
                              {order.image ? (
                                <img
                                  src={order.image}
                                  alt={order.product_name}
                                  style={{ width: "50px", height: "50px" }}
                                />
                              ) : (
                                "No Image"
                              )}
                            </td>
                            <td className="account__table--body__child--items">
                              {order.product_name}
                            </td>
                            <td className="account__table--body__child--items">
                              <FaIndianRupeeSign />
                              {order.total}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tbody class="account__table--body mobile__block">
                        {orders.map((order) => (
                          <tr
                            class="account__table--body__child"
                            key={order.order_id}
                          >
                            <td class="account__table--body__child--items">
                              <strong>Order</strong>
                              <span>{order.order_id}</span>
                            </td>
                            <td class="account__table--body__child--items">
                              <strong>Date</strong>
                              <span>{order.order_date}</span>
                            </td>
                            <td class="account__table--body__child--items">
                              <strong>Image</strong>
                              <span>
                                {order.image ? (
                                  <img
                                    src={order.image}
                                    alt={order.product_name}
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                ) : (
                                  "No Image"
                                )}
                              </span>
                            </td>
                            <td class="account__table--body__child--items">
                              <strong>Product Name</strong>
                              <span>{order.product_name}</span>
                            </td>
                            <td class="account__table--body__child--items">
                              <strong>Total</strong>
                              <span>
                                <FaIndianRupeeSign />
                                {order.total}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    <p>Please log in to view your order history.</p>
                    <button
                      onClick={() => {
                        navigate("/login");
                      }}
                      className="btn btn-primary"
                    >
                      Log In
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
       </>
      )};
    </main>
  );
};

export default Myaccount;
