import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { useLocation } from 'react-router-dom';

const ProductSection = () => {
    const navigate = useNavigate();
    const [silkCollection, setSilkCollection] = useState([]);
    const [popularSilks, setPopularSilks] = useState([]);
    const [newArrivalSilks, setNewArrivalSilks] = useState([]);
    const [activeTab, setActiveTab] = useState("tab1");
 

    // Cart context
    const { cartItems, addItemToCart, removeItemFromCart } = useContext(CartContext);

    useEffect(() => {
        fetch("https://www.vedanthfashions.com/vfadmin/vfcustomerapi/home.php")
            .then(response => response.json())
            .then(data => {
                if (data.head && data.head.code === 200) { // Ensure success from API
                    if (data.body) {
                        setSilkCollection(data.body.silks || []); // Extracting silk data from body
                        setPopularSilks(data.body.popular || []);
                        setNewArrivalSilks(data.body.new || []);
                    }
                } else {
                    console.error("API Error:", data.head.msg);
                }
            })
            .catch(error => console.error("Error fetching silk collection:", error));
    }, []);

    // Add item to cart
    const addItem = (collection, id) => {
        let item = null;
    
        if (collection === "silkCollection") {
            item = silkCollection.find(item => item.product_id === id); // Change id to product_id
        } else if (collection === "popularSilks") {
            item = popularSilks.find(item => item.product_id === id); // Change id to product_id
        } else if (collection === "newArrivalSilks") {
            item = newArrivalSilks.find(item => item.product_id === id); // Change id to product_id
        }
    
        if (item) {
            console.log(`Adding item with id: ${item.product_id}`);
            addItemToCart(item); // Ensure this updates cartItems state correctly
        } else {
            console.error(`Item with id ${id} not found in collection ${collection}`);
        }
    };
    
    
    return (
        <section className="product__section section--padding pt-0">
            <div className="container-fluid">
                <div className="section__heading text-center mb-35">
                    <h2 className="section__heading--maintitle">Vedanth Fashions</h2>
                </div>
                <ul className="product__tab--one product__tab--primary__btn d-flex justify-content-center mb-50">
                    <li
                        className={`product__tab--primary__btn__list ${activeTab === "tab1" ? 'active' : ''}`}
                        onClick={() => setActiveTab("tab1")}
                    >
                        Silks Collections
                    </li>
                    <li
                        className={`product__tab--primary__btn__list ${activeTab === "tab2" ? 'active' : ''}`}
                        onClick={() => setActiveTab("tab2")}
                    >
                        Popular Silks
                    </li>
                    <li
                        className={`product__tab--primary__btn__list ${activeTab === "tab3" ? 'active' : ''}`}
                        onClick={() => setActiveTab("tab3")}
                    >
                        New Arrival Silks
                    </li>
                </ul>

                <div className="tab_content">
                    {activeTab === "tab1" && (
                        <div id="saree" className="tab_pane active show">
                            <div className="product__section--inner">
                                <div className="row row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30">
                                    {Array.isArray(silkCollection) && silkCollection.map((item) => (
                                        <div className="col mb-30" key={item.id}>
                                            {/* {console.log("Current Item:", item)} */}
                                            <div className="product__items">
                                            <div className="product__items--thumbnail" >
                                               
                                             <a className="product__items--link"onClick={() => navigate(`/product-details?product_id=${item.product_id}&subcategory_id=${item.subcategory_id}`)}
                                             >
                                              <img 
                                                 className="product__items--img product__primary--img" 
                                                 src={item.image} 
                                                 alt={item.product_name} 
                                                 loading='lazy'
                                              />
                                             </a>
                                            </div>
 
                                                <div className="product__items--content">
                                                    <h3 className="product__items--content__title h4">
                                                        <a onClick={() => navigate(`/product-details?id=${item.product_id}`)}>{item.product_name}</a>
                                                        { console.log("PROID=========>" + item.product_id)}
                                                    </h3>
                                                    <div className="product__items--price">
                                                        <span className="current__price">
                                                            <i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {item.onlinerate}
                                                        </span>
                                                        <span className="price__divided"></span>
                                                        <span className="old__price">
                                                            <i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {item.mrprate}
                                                        </span>
                                                    </div>
                                                         <ul className="product__items--action d-flex">
                                                          {cartItems.find(cartItem => cartItem.product_id === item.product_id) === undefined ? (
                                                              <li className="product__items--action__list">
                                                                  <a className="product__items--action__btn add__to--cart" onClick={() => {
                                                                           console.log("Adding item with id:", item.product_id); // Log the product_id
                                                                              addItem("silkCollection", item.product_id); // Pass product_id instead of id
                                                                    }}>
                                                                         <svg class="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534">
                                                            <g transform="translate(0 0)">
                                                              <g>
                                                                <path data-name="Path 16787" d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z" transform="translate(0 -463.248)" fill="currentColor"></path>
                                                                <path data-name="Path 16788" d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z" transform="translate(-1.191 -466.622)" fill="currentColor"></path>
                                                                <path data-name="Path 16789" d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z" transform="translate(-2.875 -466.622)" fill="currentColor"></path>
                                                              </g>
                                                            </g>
                                                        </svg>
                                                                         <span className="add__to--cart__text"> + Add to Cart</span>
                                                                    </a>
                                                                </li>
                                                            ) : (
                                                             <li className="product__items--action__list">
                                                                 <a className="product__items--action__btn add__to--cart" onClick={() => navigate("/cart")}>
                                                                     <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534">
                                                                         {/* SVG Paths */}
                                                                     </svg>
                                                                     <span className="add__to--cart__text"> + Show Cart</span>
                                                                </a>
                                                            </li>
                                                            )}
                                                        </ul>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === "tab2" && (
                        <div id="blouse" className="tab_pane active show">
                            <div className="product__section--inner">
                                <div className="row row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30">
                                    {Array.isArray(popularSilks) && popularSilks.map((item) => (
                                        <div className="col mb-30" key={item.id}>
                                            <div className="product__items">
                                                <div className="product__items--thumbnail" onClick={() => navigate("/shop")}>
                                                    <a className="product__items--link">
                                                        <img className="product__items--img product__primary--img" src={item.image} alt={item.product_name} loading='lazy' />
                                                    </a>
                                                </div>
                                                <div className="product__items--content">
                                                    <h4 className="product__items--content__title" onClick={() => navigate(`/product-details/${item.id}`)}>
                                                        <a>{item.product_name}</a>
                                                    </h4>
                                                    <div className="product__items--price">
                                                        <span className="current__price">
                                                            <i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {item.onlinerate}
                                                        </span>
                                                        <span className="price__divided"></span>
                                                        <span className="old__price">
                                                            <i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {item.mrprate}
                                                        </span>
                                                    </div>
                                                    <ul className="product__items--action d-flex">
                                                        {cartItems.find(cartItem => cartItem.product_id === item.product_id) === undefined ? (
                                                            <li className="product__items--action__list">
                                                                <a className="product__items--action__btn add__to--cart" onClick={() => addItem("popularSilks", item.product_id)}>
                                                                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534">
                                                                        {/* SVG Paths */}
                                                                    </svg>
                                                                    <span className="add__to--cart__text"> + Add to Cart</span>
                                                                </a>
                                                            </li>
                                                        ) : (
                                                            <li className="product__items--action__list">
                                                                <a className="product__items--action__btn add__to--cart" onClick={() => navigate("/cart")}>
                                                                <svg class="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534">
                                                            <g transform="translate(0 0)">
                                                              <g>
                                                                <path data-name="Path 16787" d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z" transform="translate(0 -463.248)" fill="currentColor"></path>
                                                                <path data-name="Path 16788" d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z" transform="translate(-1.191 -466.622)" fill="currentColor"></path>
                                                                <path data-name="Path 16789" d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z" transform="translate(-2.875 -466.622)" fill="currentColor"></path>
                                                              </g>
                                                            </g>
                                                        </svg>
                                                                    <span className="add__to--cart__text"> + Show Cart</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === "tab3" && (
                        <div id="chudidhar" className="tab_pane active show">
                            <div className="product__section--inner">
                                <div className="row row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n30">
                                    {Array.isArray(newArrivalSilks) && newArrivalSilks.map((item) => (
                                        <div className="col mb-30" key={item.id}>
                                            <div className="product__items">
                                                <div className="product__items--thumbnail" onClick={() => navigate("/shop")}>
                                                    <a className="product__items--link">
                                                        <img className="product__items--img product__primary--img" src={item.image} alt={item.product_name} loading='lazy' />
                                                    </a>
                                                </div>
                                                <div className="product__items--content">
                                                    <h4 className="product__items--content__title" onClick={() => navigate(`/product-details/${item.id}`)}>
                                                        <a>{item.product_name}</a>
                                                    </h4>
                                                    <div className="product__items--price">
                                                        <span className="current__price">
                                                            <i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {item.onlinerate}
                                                        </span>
                                                        <span className="price__divided"></span>
                                                        <span className="old__price">
                                                            <i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {item.mrprate}
                                                        </span>
                                                    </div>
                                                    <ul className="product__items--action d-flex">
                                                        {cartItems.find(cartItem => cartItem.product_id === item.product_id) === undefined ? (
                                                            <li className="product__items--action__list">
                                                                <a className="product__items--action__btn add__to--cart"  onClick={() => addItem("newArrivalSilks", item.product_id)}>
                                                                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534">
                                                                        {/* SVG Paths */}
                                                                    </svg>
                                                                    <span className="add__to--cart__text"> + Add to Cart</span>
                                                                </a>
                                                            </li>
                                                        ) : (
                                                            <li className="product__items--action__list">
                                                                <a className="product__items--action__btn add__to--cart" onClick={() => navigate("/cart")}>
                                                                <svg class="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 14.706 13.534">
                                                            <g transform="translate(0 0)">
                                                              <g>
                                                                <path data-name="Path 16787" d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z" transform="translate(0 -463.248)" fill="currentColor"></path>
                                                                <path data-name="Path 16788" d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z" transform="translate(-1.191 -466.622)" fill="currentColor"></path>
                                                                <path data-name="Path 16789" d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z" transform="translate(-2.875 -466.622)" fill="currentColor"></path>
                                                              </g>
                                                            </g>
                                                        </svg>
                                                                    <span className="add__to--cart__text"> + Show Cart</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ProductSection;
