import React from 'react'

const Loader = () => {
    return (
        <div id="ctn-preloader" className="ctn-preloader">
            <div className="animation-preloader">
                <div className="spinner"></div>
                <div className="txt-loading">
                    <span data-text-preloader="V" className="letters-loading">
                        V
                    </span>

                    <span data-text-preloader="E" className="letters-loading">
                        E
                    </span>

                    <span data-text-preloader="D" className="letters-loading">
                        D
                    </span>

                    <span data-text-preloader="A" className="letters-loading">
                        A
                    </span>

                    <span data-text-preloader="N" className="letters-loading">
                        N
                    </span>

                    <span data-text-preloader="T" className="letters-loading">
                        T
                    </span>
                    <span data-text-preloader="H" className="letters-loading">
                        H
                    </span>

                    <span data-text-preloader="F" className="letters-loading">
                        F
                    </span>
                    <span data-text-preloader="A" className="letters-loading">
                        A
                    </span>
                    <span data-text-preloader="S" className="letters-loading">
                        S
                    </span>
                    <span data-text-preloader="H" className="letters-loading">
                        H
                    </span>
                    <span data-text-preloader="I" className="letters-loading">
                        I
                    </span>
                    <span data-text-preloader="O" className="letters-loading">
                        O
                    </span>
                    <span data-text-preloader="N" className="letters-loading">
                        N
                    </span>
                    <span data-text-preloader="S" className="letters-loading">
                        S
                    </span>
                </div>
            </div>
            <div className="loader-section section-left"></div>
            <div className="loader-section section-right"></div>
        </div>
    )
}

export default Loader