import React, { useContext, useEffect, useState } from 'react'
import Owlcarosal from '../components/Owlcarosal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import GLightbox from 'glightbox';
import axios from 'axios';
import 'glightbox/dist/css/glightbox.min.css';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import '../components/Cart.css'

const Cart = () => {
  const navigate = useNavigate();
  const { cartItems, setCartItems, addItemToCart, removeItemFromCart } = useContext(CartContext);
  const { handleOutsideClick } = useOutletContext()
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [gstError, setGstError] = useState(null);
  const subtotalCurrentPrice = cartItems.reduce((acc, item) => acc + item.onlinerate * item.qty, 0);

  useEffect(() => {
    // Fetch GST data from the API
    axios.post('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/gst_percentage.php')
      .then(response => {
        if (response.data.head.code === 200) {
          const gstData = response.data.body.gst[0];
          setCgst(parseFloat(gstData.cgst));
          setSgst(parseFloat(gstData.sgst));
        } else {
          setGstError('Failed to fetch GST data');
        }
      })
      .catch(error => {
        setGstError('Error fetching GST data');
      });
  }, []);
  
  // Calculate GST and grand total
  const cgstAmount = (subtotalCurrentPrice * cgst) / 100;
  const sgstAmount = (subtotalCurrentPrice * sgst) / 100;
  const grandTotal = subtotalCurrentPrice + cgstAmount + sgstAmount;

// Handle quantity change
const handleQuantityChange = (product_id, image, value) => {
  setCartItems(prevCarts =>
    prevCarts.map((item) =>
      item.product_id === product_id && item.image === image
        ? { ...item, qty: value, tot: item.onlinerate * value } // Update total as well
        : item
    )
  );
};

// Increase quantity
const qtyIncrease = (product_id, image) => {
  setCartItems(prevCarts =>
    prevCarts.map((item) =>
      item.product_id === product_id && item.image === image
        ? { ...item, qty: item.qty + 1, tot: item.onlinerate * (item.qty + 1) } // Update total
        : item
    )
  );
};

// Decrease quantity
const qtyDecrease = (product_id, image) => {
  setCartItems(prevCarts => {
    const product = prevCarts.find(item => item.product_id === product_id && item.image === image);
    if (product && product.qty > 1) {
      return prevCarts.map((item) =>
        item.product_id === product_id && item.image === image
          ? { ...item, qty: item.qty - 1, tot: item.tot - item.onlinerate } // Update total
          : item
      );
    }
    return prevCarts; // No change if qty is 1 or less
  });
};

  

  /* //remove cart
  const removeItem = (product_id) => {
    setCartItems(prevCarts => prevCarts.filter(item => item.product_id !== product_id));
  }; */

  // Function to calculate subtotal

    // Calculate subtotal based on cart items
    const calculateSubtotal = () => {
      return cartItems.reduce((acc, item) => {
        return acc + (item.qty * item.onlinerate); // Assuming onlinerate is the price per unit
      }, 0);
    };
  
    const subtotal = calculateSubtotal();
    // const grandTotal = subtotal; // You can add additional logic for discounts, shipping, etc.
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    GLightbox({
      selector: '.glightbox'
    });
  }, []);
  return (
    <>
      <main class="main__content_wrapper" onClick={handleOutsideClick}>

        {/* <!-- Start breadcrumb section --> */}
        <section class="breadcrumb__section breadcrumb__bg">
          <div class="container">
            <div class="row row-cols-1">
              <div class="col">
                <div class="breadcrumb__content text-center">
                  <h1 class="breadcrumb__content--title text-white mb-25">Shopping Cart</h1>
                  <ul class="breadcrumb__content--menu d-flex justify-content-center">
                    <li class="breadcrumb__content--menu__items"><a class="text-white" onClick={() => { navigate("/") }}>Home</a></li>
                    <li class="breadcrumb__content--menu__items"><span class="text-white">Shopping Cart</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End breadcrumb section --> */}


        {/* Cart Section Start */}
        <section className="cart__section section--padding">
          <div className="container-fluid">
            <div className="cart__section--inner">
              <form action="#">
                <h2 className="cart__title mb-40">Shopping Cart</h2>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="cart__table">
                      <table className="cart__table--inner">
                        <thead className="cart__table--header">
                          <tr className="cart__table--header__items">
                            <th className="cart__table--header__list">Product</th>
                            <th className="cart__table--header__list">Price</th>
                            <th className="cart__table--header__list">Quantity</th>
                            <th className="cart__table--header__list">Total</th>
                          </tr>
                        </thead>
                        <tbody className="cart__table--body">
                          {Array.isArray(cartItems) && cartItems.map(item => (
                            <tr key={item.id} className="cart__table--body__items">
                              <td className="cart__table--body__list">
                                <div className="cart__product d-flex align-items-center">
                                  <button className="cart__remove--btn" aria-label="remove button" type="button" onClick={() => removeItemFromCart(item.product_id, item.image)}>
                                    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px">
                                      <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
                                    </svg>
                                  </button>
                                  <div className="cart__thumbnail">
                                    <a onClick={() => { navigate("/product-details") }}>
                                      <img className="border-radius-5" src={item.image} alt="cart-product" loading='lazy' />
                                    </a>
                                  </div>
                                  <div className="cart__content">
                                    <h4 className="cart__content--title">
                                      <a onClick={() => { navigate("/product-details") }}>{item.product_name}</a>
                                    </h4>
                                    {/* <span className="cart__content--variant">COLOR: 2kg</span>
                                    <span className="cart__content--variant">WEIGHT: red</span> */}
                                  </div>
                                </div>
                              </td>
                              <td className="cart__table--body__list">
                                <span className="cart__price"><FaIndianRupeeSign/>{item.onlinerate}</span>
                              </td>
                              <td className="cart__table--body__list">
                                <div className="quantity__box">
                                  <button type="button" className="quantity__value quickview__value--quantity decrease" aria-label="quantity value" value="Decrease Value" onClick={() => { qtyDecrease(item.product_id, item.image) }}>-</button>
                                  <label>
                                    <input type="number" className="quantity__number quickview__value--number" value={item.qty} data-counter onChange={(e) => handleQuantityChange(item.product_id, item.image, Number(e.target.value))} />
                                  </label>
                                  <button type="button" className="quantity__value quickview__value--quantity increase" aria-label="quantity value" value="Increase Value" onClick={() => { qtyIncrease(item.product_id, item.image) }}>+</button>
                                </div>
                              </td>
                              <td className="cart__table--body__list">
                                <span className="cart__price end"><FaIndianRupeeSign/>{item.tot == 0 ? item.onlinerate : item.tot}</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="continue__shopping d-flex justify-content-between">
                        <a className="continue__shopping--link" onClick={() => { navigate('/shop') }}>Continue shopping</a>
                        <button className="continue__shopping--clear" type="submit">Clear Cart</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="cart__summary border-radius-10">
                      {/* <div className="coupon__code mb-30">
                        <h3 className="coupon__code--title">Coupon</h3>
                        <p className="coupon__code--desc">Enter your coupon code if you have one.</p>
                        <div className="coupon__code--field d-flex">
                          <label>
                            <input className="coupon__code--field__input border-radius-5" placeholder="Coupon code" type="text" />
                          </label>
                          <button className="coupon__code--field__btn primary__btn" type="submit">Apply Coupon</button>
                        </div>
                      </div> */}
                      {/* <div className="cart__note mb-20">
                        <h3 className="cart__note--title">Note</h3>
                        <p className="cart__note--desc">Add special instructions for your seller...</p>
                        <textarea className="cart__note--textarea border-radius-5"></textarea>
                      </div> */}
                      <div className="cart__summary--total mb-20">
                        <table className="cart__summary--total__table">
                          <tbody>
                            <tr className="cart__summary--total__list">
                              <td className="cart__summary--total__title text-left">SUBTOTAL</td>
                              <td className="cart__summary--amount text-right"><i style={{ fontSize: '13px' }} className="fa">&#xf156;</i> {subtotalCurrentPrice.toFixed(2)}</td>
                            </tr>
                            <tr className="cart__summary--total__list">
          <td className="cart__summary--total__title text-left">CGST ({cgst}%)</td>
          <td className="checkout__total--amount text-right">
            <i style={{ fontSize: '13px' }} className="fa">&#xf156;</i> {cgstAmount.toFixed(2)}
          </td>
        </tr>
        <tr className="cart__summary--total__list">
          <td className="cart__summary--total__title text-left">SGST ({sgst}%)</td>
          <td className="checkout__total--amount text-right">
            <i style={{ fontSize: '13px' }} className="fa">&#xf156;</i> {sgstAmount.toFixed(2)}
          </td>
        </tr>
                            <tr className="cart__summary--total__list">
                              <td className="cart__summary--total__title text-left">GRAND TOTAL</td>
                              <td className="cart__summary--amount text-right"><i style={{ fontSize: '13px' }} className="fa">&#xf156;</i>{grandTotal.toFixed(2)} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="cart__summary--footer">
                        <p className="cart__summary--footer__desc">Inclusive Of All Taxes</p>
                        <ul className="d-flex justify-content-between">
                          {/* <li><button className="cart__summary--footer__btn primary__btn cart" type="submit">Update Cart</button></li> */}
                          <li><a className="cart__summary--footer__btn primary__btn checkout" onClick={() => { navigate("/checkout") }}>Check Out</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        {/* Cart Section end */}

        {/* <!-- Start product section --> */}
        {/* <section class="product__section section--padding pt-0">
          <div class="container-fluid">
            <div class="section__heading text-center mb-50">
              <h2 class="section__heading--maintitle">New Products</h2>
            </div>
          </div>
          <div class="product__section--inner product__swiper--activation swiper  vijay">

            <Owlcarosal slides={products} />

          </div>
        </section> */}

        {/* End Product section */}

        {/* <!-- Start shipping section --> */}
        {/* <section class="shipping__section2 shipping__style3 section--padding">
          <div class="container">
            <div class="shipping__section2--inner shipping__style3--inner d-flex justify-content-between">
              <div class="shipping__items2 d-flex align-items-center">
                <div class="shipping__items2--icon">
                  <img src="assets/img/other/shipping1.png" alt="" />
                </div>
                <div class="shipping__items2--content">
                  <h2 class="shipping__items2--content__title h3">Shipping</h2>
                  <p class="shipping__items2--content__desc">From handpicked sellers</p>
                </div>
              </div>
              <div class="shipping__items2 d-flex align-items-center">
                <div class="shipping__items2--icon">
                  <img src="assets/img/other/shipping5.png" alt="" style={{height:"80px",width:"85px"}}/>
                </div>
                <div class="shipping__items2--content">
                  <h2 class="shipping__items2--content__title h3">Payment</h2>
                  <p class="shipping__items2--content__desc">From handpicked sellers</p>
                </div>
              </div>
              <div class="shipping__items2 d-flex align-items-center">
                <div class="shipping__items2--icon">
                  <img src="assets/img/other/shipping3.png" alt="" />
                </div>
                <div class="shipping__items2--content">
                  <h2 class="shipping__items2--content__title h3">Return</h2>
                  <p class="shipping__items2--content__desc">From handpicked sellers</p>
                </div>
              </div>
              <div class="shipping__items2 d-flex align-items-center">
                <div class="shipping__items2--icon">
                  <img src="assets/img/other/shipping4.png" alt="" />
                </div>
                <div class="shipping__items2--content">
                  <h2 class="shipping__items2--content__title h3">Support</h2>
                  <p class="shipping__items2--content__desc">From handpicked sellers</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- End shipping section --> */}
      </main>
        </>
        )
}

export default Cart