import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [privacyContent, setPrivacyContent] = useState('');
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await axios.get('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/content.php');
        console.log('API Response:', response.data); // Check the structure here
        const privacyData = response.data.body.privacy?.value || '<p>No content available</p>'; // Correct path
        setPrivacyContent(privacyData);
      } catch (error) {
        console.error('Error fetching privacy policy:', error);
        setPrivacyContent('<p>Error loading privacy policy</p>');
      }
    };

    fetchPrivacyPolicy();
  }, []);
  return (
    <main className="main__content_wrapper">
      {/* <!-- Start breadcrumb section --> */}
      <section className="breadcrumb__section breadcrumb__bg">
        <div className="container">
          <div className="row row-cols-1">
            <div className="col">
              <div className="breadcrumb__content text-center">
                <h1 className="breadcrumb__content--title text-white mb-25">Privacy Policy</h1>
                <ul className="breadcrumb__content--menu d-flex justify-content-center">
                  <li className="breadcrumb__content--menu__items">
                    <a className="text-white" onClick={() => navigate('/')}>Home</a>
                  </li>
                  <li className="breadcrumb__content--menu__items">
                    <span className="text-white">Privacy Policy</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End breadcrumb section --> */}

      {/* <!-- Start privacy policy section --> */}
      <div className="privacy__policy--section section--padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="privacy__policy--content">
                {/* Displaying the content from the API */}
                <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End privacy policy section --> */}

      {/* <!-- Start shipping section --> */}
      <section class="shipping__section2 shipping__style3 section--padding pt-0">
            <div class="container">
                <div class="shipping__section2--inner shipping__style3--inner d-flex justify-content-between">
                    <div class="shipping__items2 d-flex align-items-center">
                        <div class="shipping__items2--icon">
                            <img src="assets/img/other/shipping1.png" alt=""/>
                        </div>
                        <div class="shipping__items2--content">
                            <h2 class="shipping__items2--content__title h3">Shipping</h2>
                            <p class="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div class="shipping__items2 d-flex align-items-center">
                        <div class="shipping__items2--icon">
                            <img src="assets/img/other/shipping2.png" alt=""/>
                        </div>
                        <div class="shipping__items2--content">
                            <h2 class="shipping__items2--content__title h3">Payment</h2>
                            <p class="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div class="shipping__items2 d-flex align-items-center">
                        <div class="shipping__items2--icon">
                            <img src="assets/img/other/shipping3.png" alt=""/>
                        </div>
                        <div class="shipping__items2--content">
                            <h2 class="shipping__items2--content__title h3">Return</h2>
                            <p class="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div class="shipping__items2 d-flex align-items-center">
                        <div class="shipping__items2--icon">
                            <img src="assets/img/other/shipping4.png" alt=""/>
                        </div>
                        <div class="shipping__items2--content">
                            <h2 class="shipping__items2--content__title h3">Support</h2>
                            <p class="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      {/* <!-- End shipping section --> */}
    </main>
  );
};

export default PrivacyPolicy;
