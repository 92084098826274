import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CartContext } from '../context/CartContext';

const OffcanvasCart = ({ cartOpen, setCartOpen }) => {

  const { cartItems, cartCount, setCartItems, removeItemFromCart } = useContext(CartContext);
  const navigate = useNavigate();

 // Handle quantity change based on product_id and image
const handleQuantityChange = (product_id, image, value) => {
  setCartItems(prevCarts =>
    prevCarts.map(item =>
      item.product_id === product_id && item.image === image
        ? { ...item, qty: value, tot: item.currentPrice * value } // Update total based on new quantity
        : item
    )
  );
};

// Increase quantity based on product_id and image
const qtyIncrease = (product_id, image) => {
  setCartItems(prevCarts => 
    prevCarts.map(item => 
      item.product_id === product_id && item.image === image 
        ? { ...item, qty: item.qty + 1, tot: item.onlinerate * (item.qty + 1) } // Update total based on new quantity
        : item
    )
  );
};

// Decrease quantity based on product_id and image
const qtyDecrease = (product_id, image) => {
  setCartItems(prevCarts => {
    const product = prevCarts.find(item => item.product_id === product_id && item.image === image);
    if (product && product.qty > 1) {
      return prevCarts.map(item => 
        item.product_id === product_id && item.image === image 
          ? { ...item, qty: item.qty - 1, tot: item.tot - item.onlinerate } // Update total based on new quantity
          : item
      );
    }
    return prevCarts; // No change if qty is 1 or less
  });
};


 //remove cart
 const removeItem = (product_id) => {
  setCartItems(prevCarts => prevCarts.filter(item => item.product_id !== product_id));
};

  // Calculate subtotal
  const subtotalCurrentPrice = cartItems.reduce((acc, item) => acc + item.onlinerate * item.qty, 0);


  return (
    <div className={`offCanvas__minicart ${!cartOpen ? "" : "active"}`}>
      <div className="minicart__header">
        <div className="minicart__header--top d-flex justify-content-between align-items-center">
          <h2 className="minicart__title h3">Shopping Cart</h2>
          <button className="minicart__close--btn" aria-label="minicart close button" data-offcanvas onClick={() => { setCartOpen(curr => !curr) }}>
            <svg className="minicart__close--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368" />
            </svg>
          </button>
        </div>
        <p className="minicart__header--desc">Clothing and fashion products are limited</p>
      </div>
      <div className="minicart__product">
        {Array.isArray(cartItems) && cartItems.map((item) => (
          <div className="minicart__product--items d-flex">
            <div className="minicart__thumb">
              <a onClick={()=>{navigate("/product-details")}}>
                <img src={item.image} alt={item.product_name} loading='lazy' />
              </a>
            </div>
            <div className="minicart__text">
              <h3 className="minicart__subtitle h4">
                <a onClick={()=>{navigate('/product-details')}}>{item.product_name}</a>
              </h3>
              {/* <span className="color__variant"><b>Color:</b> Beige</span> */}
              <div className="minicart__price">
                <span className="current__price"><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i>{item.onlinerate}</span>{" "}
                <span className="old__price"><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i>{item.mrprate}</span>{" "}<br /> 
                <span className="current__price_total"><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i>{item.tot > 0 ? item.tot : item.onlinerate}</span>
              </div>
              <div className="minicart__text--footer d-flex align-items-center">
                <div className="quantity__box minicart__quantity">
                  <button type="button" className="quantity__value decrease" aria-label="quantity value" value="Decrease Value" onClick={() => { qtyDecrease(item.product_id, item.image) }}>-</button>
                  <label>
                    <input type="number" className="quantity__number" name='qty' value={item.qty} data-counter onChange={(e) => handleQuantityChange(item.product_id, item.image, Number(e.target.value))} />
                  </label>
                  <button type="button" className="quantity__value increase" aria-label="quantity value" value="Increase Value" onClick={() => { qtyIncrease(item.product_id, item.image) }}>+</button>
                </div>
                <button className="minicart__product--remove" onClick={() => removeItemFromCart(item.product_id, item.image)}>Remove</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="minicart__amount">
        {/* <div className="minicart__amount_list d-flex justify-content-between">
          <span>Sub Total:</span>
          <span><b><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i> {}</b></span>
        </div> */}
        <div className="minicart__amount_list d-flex ">
          <span>Total:</span>
          <span style={{marginLeft:"215px"}}><b><i style={{ fontSize: "13px" }} className="fa">&#xf156;</i>{subtotalCurrentPrice}</b></span>
        </div>
      </div>
      <div className="minicart__conditions text-center">
        <input className="minicart__conditions--input" id="accept" type="checkbox"/>
        <label className="minicart__conditions--label" htmlFor="accept">I agree with the <a className="minicart__conditions--link"onClick={()=>{navigate("/privacypolicy")}}>Privacy and Policy</a></label>
      </div>
      <div className="minicart__button d-flex justify-content-center">
        <a className="primary__btn minicart__button--link" onClick={() => { navigate("/cart") }}>View Cart</a>
        <a className="primary__btn minicart__button--link" onClick={()=>{navigate("/checkout")}}>Checkout</a>
      </div>
    </div>
  )
}

export default OffcanvasCart

