import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../components/Slider.css';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { Link, useNavigate } from 'react-router-dom';

export default function App() {
  const [banners, setBanners] = useState([]);
  const navigate = useNavigate();

  // Fetch the banner data from the API
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch('https://www.vedanthfashions.com/vfadmin/vfcustomerapi/home.php', {
          method: 'POST',
        });
        const data = await response.json();
        if (data.head.code === 200) {
          setBanners(data.body.banner); // Set banners to state
        } else {
          console.error('Failed to fetch banners');
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  return (
    <>
  <Swiper
    pagination={{ type: 'bullets', clickable: true }}
    autoplay={{ delay: 3000, disableOnInteraction: false }}
    speed={1200}
    navigation={true}
    modules={[Autoplay, Navigation, Pagination]}
    className="swiper-wrapper"
  >
    {/* Map over the banners array and render each banner as a SwiperSlide */}
    {banners.map((banner) => (
      <SwiperSlide key={banner.id}>
        <div className="swiper-slide">
          <img src={banner.image} alt={banner.name || 'Banner'} className="img-fluid" />
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
</>
  )
}
